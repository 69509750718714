import { Map } from 'immutable';

import districtSelectors from './district';

import { getLeafletCrsFromGrid } from '@/utils/mapUtils';

import { wmsRootGroupKey } from '../../mapConfig';

const getMapPortalByShortName = (state, prefix, short_name) => {
  const district = state.mapPortals.get(prefix, null);
  if (district) {
    return district.find(v => v.get('short_name') === short_name);
  }
  return null;
};

const getDistrictMapPortalByID = (state, prefix, id) => {
  const district = state.mapPortals.get(prefix, null);
  if (district) {
    return district.find(v => v.get('id') === id);
  } else return null;
};

const getMapPortalId = (state, prefix, short_name) => {
  const map_portal = getMapPortalByShortName(state, prefix, short_name);
  if (!map_portal) {
    return null;
  }
  return map_portal.get('id');
};

const getProjectLayers = (state, projectId) =>
  state.mapPortals.getIn(['projectLayers', projectId]);

const getCurrentCompositionId = state =>
  state.mapPortals.get('currentCompositionId');

// Selektor może przyjąć short_name lub id portalu mapowego
const getMapPortalCompositions = (state, district, shortName, mapPortalId) => {
  let id = mapPortalId;

  if (shortName) {
    id = getMapPortalId(state, district, shortName);
  }

  return state.mapPortals.getIn(['mapPortalCompositions', district, id]);
};

const getMapPortalGrid = ({ state, prefix, shortName, mapPortal = null }) => {
  const mapPortalData =
    mapPortal || getMapPortalByShortName(state, prefix, shortName);

  // We need to accept a plain JS object or Immutable Map.
  return mapPortalData?.grid || mapPortalData?.get('grid');
};

const selectors = {
  getDistrictMapPortals: (state, prefix) => state.mapPortals.get(prefix, null),
  getDistrictQgisProjects: (state, prefix) =>
    state.mapPortals.get('qgis_projects').get(prefix, null),
  getDistrictQgisProject: (state, prefix, projectId) =>
    state.mapPortals
      .getIn(['qgis_projects', prefix])
      ?.find(project => project.get('id') === Number(projectId)),
  getDistrictCompositions: (state, prefix) =>
    state.mapPortals.get('district_compositions').get(prefix, null),
  getMapPortals: (state, prefix) => state.mapPortals.get(prefix),
  getDistrictsMapPortals: state => {
    const districtsMapPortals = {};
    const districts = districtSelectors.getDistricts(state);

    districts.forEach(district => {
      const mapPortals = state.mapPortals.get(district);
      /* istanbul ignore next */
      if (mapPortals) districtsMapPortals[district] = mapPortals;
    });

    return Map(districtsMapPortals);
  },
  getSingleDistrictMapPortal: state =>
    state.mapPortals.get('singleDistrictMapPortal'),
  getMapPortalByShortName,
  getDistrictMapPortalByID,
  getMapPortalId,
  fetchingDistrictMapPortals: state =>
    state.mapPortals.get('fetchingDistrictMapPortals'),
  fetchedDistrictMapPortals: state =>
    state.mapPortals.get('fetchedDistrictMapPortals'),
  getMapPortalCompositions,
  getMapPortalComposition: (
    state,
    district,
    shortName,
    mapPortalId,
    compositionId
  ) => {
    const mapPortalCompositions = getMapPortalCompositions(
      state,
      district,
      shortName,
      mapPortalId
    );

    if (!mapPortalCompositions) return null;

    return mapPortalCompositions.find(({ id }) => id === compositionId);
  },
  getMapPortalCompositionByCurrentCompositionId: (
    state,
    district,
    shortName,
    mapPortalId,
    currentCompositionId
  ) => {
    const mapPortalCompositions = getMapPortalCompositions(
      state,
      district,
      shortName,
      mapPortalId
    );

    if (!mapPortalCompositions) return null;

    return mapPortalCompositions.find(
      ({ composition: { id } }) => currentCompositionId === id
    );
  },
  getCompositionByID: (state, id) => {
    const currentCompositionId = state.mapPortals.get('currentCompositionId');
    const compositionId = id || currentCompositionId;

    return state.mapPortals.getIn(['compositions', String(compositionId)]);
  },
  getCurrentComposition: (state, prefix, portal) => {
    const mapPortalId = getMapPortalId(state, prefix, portal);
    const currentCompositionId = state.mapPortals.get('currentCompositionId');
    const mapPortalCompositions = getMapPortalCompositions(
      state,
      prefix,
      null,
      mapPortalId
    );

    if (!mapPortalCompositions) {
      return null;
    }

    return mapPortalCompositions.find(
      composition => composition.composition.id === currentCompositionId
    );
  },
  getCurrentCompositionId,
  getRasterTimelines: state => state.mapPortals.get('rasterTimelines'),
  getVectorTimelines: state => state.mapPortals.get('vectorTimelines'),
  getAddedWms: (state, compositionId) => {
    const composition = compositionId || getCurrentCompositionId(state);
    return state.mapPortals.getIn(['addedWms', composition]);
  },

  getAddedCsv: (state, compositionId) => {
    const composition = compositionId || getCurrentCompositionId(state);
    return state.mapPortals.getIn(['addedCsv', composition]);
  },

  getMapPortalTools: (state, district, portalName) => {
    const mapPortal = getMapPortalByShortName(state, district, portalName);

    if (!mapPortal) return null;

    return mapPortal.get('toolbar_map_tools');
  },
  getActiveTool: state => state.mapPortals.get('activeTool'),
  getActiveToolIndex: state => state.mapPortals.get('activeToolIndex'),
  getOrderedTags: (state, district, portalName) => {
    const mapPortal = getMapPortalByShortName(state, district, portalName);
    return mapPortal.get('map_portal_tags');
  },
  getLayersIdMap: state => state.mapPortals.get('layersIdMap'),
  getLegendVisibility: state => state.mapPortals.get('isLegendVisible'),
  getProjectLayers,
  getProjectCompositionLayers: (state, projectId, compositionName) => {
    const projectLayers = getProjectLayers(state, projectId);
    return projectLayers?.filter(layer =>
      layer.compositions.find(({ name }) => name === compositionName)
    );
  },
  getMapPortalSpotLayers: (state, prefix, mapPortalId) => {
    const mapPortal = getDistrictMapPortalByID(
      state,
      prefix,
      Number(mapPortalId)
    );
    return mapPortal ? mapPortal.get('measurment_layers') : null;
  },
  getMapPortalTeryt: (state, prefix, portal) => {
    const mapPortalId = getMapPortalId(state, prefix, portal);
    const mapPortal = getDistrictMapPortalByID(
      state,
      prefix,
      Number(mapPortalId)
    );
    return mapPortal ? mapPortal.get('teryt') : null;
  },
  getSearchEngineSource: (state, prefix, shortName) => {
    const mapPortal = getMapPortalByShortName(state, prefix, shortName);
    return mapPortal ? mapPortal.get('address_search_engine_source') : null;
  },

  getPlotSearchEngineSource: (state, prefix, shortName) => {
    const mapPortal = getMapPortalByShortName(state, prefix, shortName);

    return mapPortal ? mapPortal.get('plot_search_engine_source') : null;
  },

  getSpotMeasureConfig: (state, prefix, mapPortalId) =>
    state.mapPortals.getIn(['spotMeasureConfigs', prefix, mapPortalId]),
  getEnableGeolocation: state => state.mapPortals.get('enabledGeolocation'),
  getGeolocationCoords: state => state.mapPortals.get('geolocationCoords'),
  getSetingGeolocation: state => state.mapPortals.get('setingGeolocation'),
  getCompositionSidebarSettings: (state, district, mapPortalCompositionId) =>
    state.mapPortals.getIn([
      'compositionsSidebarSettings',
      district,
      mapPortalCompositionId
    ]),
  getProjectUpdateLogs: (state, district, projectId) =>
    state.mapPortals.getIn(['projectsLogs', district, projectId]),
  getTouchedGroups: state => state.mapPortals.get('touchedGroups'),
  getVoivodeships: state => state.mapPortals.get('voivodeships'),
  getCounties: state => state.mapPortals.get('counties'),
  getCommunes: state => state.mapPortals.get('communes'),
  getRegions: state => state.mapPortals.get('regions'),
  getFetchedLegendParams: state =>
    state.mapPortals.getIn(['fetchingLegend', 'params']),
  getServicePath: state => state.mapPortals.get('servicePath'),
  getCapabilitiesPath: state => state.mapPortals.get('capabilities'),
  getSearchInputData: state => state.mapPortals.get('searchInputData'),
  getSearchInputOptions: state => state.mapPortals.get('searchInputOptions'),
  getSearchInputGugikAddress: state =>
    state.mapPortals.get('searchInputGugikAddress'),
  getSearchInputGugikData: state =>
    state.mapPortals.get('searchInputGugikData'),
  getSearchInputGugikDisplay: state =>
    state.mapPortals.get('searchInputGugikDisplay'),
  getSelectionMethods: state => state.mapPortals.get('selectionMethods'),
  getFetchingSelectionMethods: state =>
    state.mapPortals.get('fetchingSelectionMethods'),
  getSelectionTypes: state => state.mapPortals.get('selectionTypes'),
  getFetchingSelectionTypes: state =>
    state.mapPortals.get('fetchingSelectionTypes'),
  getSelectionOperators: state => state.mapPortals.get('selectionOperators'),
  getFetchingSelectionOperators: state =>
    state.mapPortals.get('fetchingSelectionOperators'),
  getMapPortalCompositionArConfigs: (state, district, mapPortalConpositionId) =>
    state.mapPortals.getIn(['arConfigs', district, mapPortalConpositionId]),
  getArConfigAttrsList: (state, district, mapPortalConpositionId, configId) =>
    state.mapPortals.getIn([
      'arConfigsAttrs',
      district,
      mapPortalConpositionId,
      configId
    ]),
  getMapPortalSelectionSettings: (state, prefix, mapPortalId) => {
    const district = state.mapPortals.get(prefix)?.toJS();

    if (district) {
      const {
        enable_shape_selection,
        enable_layer_selection,
        enable_sketch_selection,
        enable_selection_selection,
        enable_predefined_selection,
        enable_sql_query_selection,
        enable_nearest_selection,
        enable_threshold_area,
        threshold_area
      } = district.find(({ id }) => id === mapPortalId);

      return {
        enable_shape_selection,
        enable_layer_selection,
        enable_sketch_selection,
        enable_selection_selection,
        enable_predefined_selection,
        enable_sql_query_selection,
        enable_nearest_selection,
        enable_threshold_area,
        threshold_area
      };
    } else return null;
  },
  getArCompositionConfig: (state, district, mapPortalConpositionId) =>
    state.mapPortals.getIn([
      'arCompositionsConfigs',
      district,
      mapPortalConpositionId
    ]),
  getMapPortalToolsConfiguration: state =>
    state.mapPortals.get('mapPortalToolsConfiguration'),
  getInitialSelectedRowKeys: state =>
    state.mapPortals.get('initialSelectedRowKeys'),
  getFetchingMapPortalToolsConfiguration: state =>
    state.mapPortals.get('isFetchingMapPortalTools'),
  getDefaultMode: state => state.mapPortals.get('defaultMode'),
  getUpdatingMapPortalTool: state =>
    state.mapPortals.get('isUpdatingMapPortalTool'),
  getCompositionWmsRootGroup: state => {
    const compositionId = getCurrentCompositionId(state);
    const legendEntries = state.mapPortals.getIn([
      'compositions',
      String(compositionId),
      'legend'
    ]);

    const wmsRootGroup = legendEntries?.find(
      ({ id }) => id === wmsRootGroupKey
    );
    return wmsRootGroup;
  },
  isFetchingDistrictLocalities: state =>
    state.mapPortals.get('isFetchingDistrictLocalities'),
  currentDistrictLocality: (state, district) =>
    state.mapPortals.getIn(['districtLocalities', district]),
  isFetchingLocalityStreets: state =>
    state.mapPortals.get('isFetchingLocalityStreets'),
  currentLocalityStreets: (state, simc) =>
    state.mapPortals.getIn(['localityStreets', simc]),
  getFetchingWmsLegend: state => state.mapPortals.get('fetchingWmsLegend'),
  getCurrentMapPortalNames: state => state.mapPortals.get('mapPortalsNames'),
  getFilterEmpty: state => state.mapPortals.get('filterEmpty'),
  getMapPortalGrid,
  getMapPortalCrs: params => {
    const grid = getMapPortalGrid(params);
    return grid ? getLeafletCrsFromGrid(grid) : null;
  },
  getServiceLayer: state => state.mapPortals.get('serviceLayer'),
  getIframePortalData: state => state.mapPortals.get('iframePortalData')
};

export default selectors;
