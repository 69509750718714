import { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import { notification } from 'antd';

import { globalApi } from '@/store/api/global';

import {
  globalSelectors,
  districtSelectors,
  userSelectors
} from '../../store/selectors';
import {
  hideError,
  hideSuccess,
  setMapPortalLoader,
  setRenderedComponents
} from '../../store/actions/globalActions';
import { setScalesDenominators } from '../../store/actions/mapActions';
import { confirmLoginUser } from '../../store/actions/userActions';

import LoaderLayer from '../../components/LoaderLayer';
import LoaderFullScreen from '../../components/LoaderFullScreen';
import SuspenseWrapper from '../../components/SuspenseWrapper';
import PortalRouter from '../../router/portal';

import handleError from '../../utils/handleError';
import isMatomoInstalled from '../../utils/matomo/isMatomoInstalled';
import isGoogleAnalyticsInstalled from '../../utils/googleAnalytics/isGoogleAnalyticsInstalled';
import { getBrowserType } from '../../utils/lib';
import { COMPONENTS_KEYS } from '../../utils/constants';
import { ResponseStatus } from '../../shapes';
import { UserDataShape } from '../../shapes/user';

import {
  responseStatuses,
  matomoTrackerId,
  gaTrackerId,
  localStorageKeys
} from '../../config';

import './MainView.less';

const MaintenancePage = lazy(() => import('../../views/MaintenancePage'));
const CookiesInfo = lazy(() => import('../../components/CookiesInfo'));

export class MainView extends Component {
  state = {
    isCookiesAccepted: false,
    isLoaderAdded: false
  };

  componentDidMount() {
    this.initializeApp();
    this.props.setRenderedComponents(COMPONENTS_KEYS.MainView);
    this.setState({
      isCookiesAccepted: !!localStorage.getItem(
        localStorageKeys.cookiesAccepted
      )
    });
  }

  componentDidUpdate(prevProps) {
    this.showMessage(prevProps.message);

    if (this.state.isCookiesAccepted) this.changeCookiesConsent();
  }

  initializeSentry = async settings => {
    const Sentry = await import('@sentry/browser');

    const { enable_sentry_fe, sentry_key_fe, sentry_url_fe } = settings;

    if (enable_sentry_fe) {
      Sentry.init({
        dsn: `https://${sentry_key_fe}@${sentry_url_fe}`,
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications.'
        ]
      });
    }
  };

  initializeApp = async () => {
    let settings;

    try {
      settings = await this.props.fetchGlobalSettings({}, true).unwrap();
    } catch (err) {
      handleError(err);

      notification.error({
        message: this.props.intl.formatMessage({
          id: 'global_settings_fetching_error',
          defaultMessage:
            'Global settings loading error - portal may not work properly!'
        })
      });
    }

    if (!settings) return;

    if (process.env.NODE_ENV === 'production') this.initializeSentry(settings);
    this.initializeMapConfig(settings.default_grid);
    this.tryLogin();
  };

  changeCookiesConsent = () => {
    const settings = this.props.globalSettings?.toJS();

    if (!settings) return;

    this.initializeMatomo(settings.matomo_site_id, settings.matomo_url);
    this.initializeGoogleAnalytics(settings.google_analytics);
  };

  initializeMatomo = (matomoSiteId, matomoURL) => {
    if (!matomoSiteId || isMatomoInstalled()) return;
    matomoURL = matomoURL ?? '//matomo.giap.pl/';

    const script = document.createElement('script');
    script.setAttribute('id', matomoTrackerId);
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = `var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['appendToTrackingUrl', 'new_visit=1']);
    _paq.push(['trackPageView']);
    _paq.push(['setCustomVariable', 1, 'ID sesji', '${uuid()}', 'visit']);
    _paq.push(['appendToTrackingUrl', '']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="${matomoURL}";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '${matomoSiteId}']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();`;

    document.head.appendChild(script);
  };

  initializeGoogleAnalytics = trackingId => {
    if (!trackingId || isGoogleAnalyticsInstalled()) return;

    const gtagScript = document.createElement('script');
    gtagScript.setAttribute('id', gaTrackerId);
    gtagScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
    );

    const script = document.createElement('script');
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${trackingId}', { send_page_view: false });`;

    document.head.appendChild(gtagScript);
    document.head.appendChild(script);
  };

  initializeMapConfig = ({ scales }) => {
    const denominators = scales.map(({ scale_denom }) => +scale_denom);
    this.props.setScalesDenominators(denominators);
  };

  tryLogin = () => {
    const userData = this.props.userData?.toJS();
    const { jwt } = localStorageKeys;

    if (window.localStorage.getItem(jwt) && !userData)
      this.props.confirmLoginUser();
  };

  showMessage = prevMessage => {
    if (
      !this.props.showMessage ||
      !this.props.message ||
      this.props.message === prevMessage
    )
      return;

    const data = {
      message: this.props.message,
      duration: 4
    };

    if (this.props.messageType === 'error') {
      return notification.error({
        ...data,
        onClose: this.props.hideError
      });
    }

    notification.success({
      ...data,
      onClose: this.props.hideSuccess
    });
  };

  handleCookiesSave = () => {
    localStorage.setItem(localStorageKeys.cookiesAccepted, true);
    this.setState({ isCookiesAccepted: true });
  };

  checkIfUrlMatchesPatternWithAnyPrefix = () => {
    const pathname = window.location.pathname;
    const pattern = /^\/(?!admin$)[^/]+\/[^/]+$/;

    return pattern.test(pathname);
  };

  render() {
    const { SUCCESS, FAILED } = responseStatuses;
    const { formatMessage } = this.props.intl;
    const { isLoaderAdded, isCookiesAccepted } = this.state;

    const languagesLoaded =
      this.props.languagesFetching === SUCCESS ||
      this.props.languagesFetching === FAILED;
    const translationLoaded =
      this.props.translationFetching === SUCCESS ||
      this.props.translationFetching === FAILED;
    const defaultApiFetching =
      this.props.defaultApiFetching === SUCCESS ||
      this.props.defaultApiFetching === FAILED;

    if (defaultApiFetching && !this.props.isApiAvailable) {
      return (
        <SuspenseWrapper>
          <MaintenancePage />
        </SuspenseWrapper>
      );
    }

    if (
      !languagesLoaded ||
      !translationLoaded ||
      !this.props.globalSettings.size
    ) {
      return <LoaderLayer />;
    }

    if (
      this.props.areDistrictsFetched === false &&
      this.props.areDistrictsBeingFetched
    ) {
      return (
        <LoaderLayer
          tip={formatMessage({
            id: 'portal_loading_districts_list',
            defaultMessage: 'Loading districts list...'
          })}
        />
      );
    }

    const result = this.checkIfUrlMatchesPatternWithAnyPrefix();

    const queryParams = new URLSearchParams(window.location.search);
    const isCookieInfoHidden = queryParams.has('hide_cookie');

    if (result && !isLoaderAdded) {
      this.props.setMapPortalLoader(true);
      this.setState({ isLoaderAdded: true });
    }

    return (
      <div className={classNames('app__container', getBrowserType())}>
        <PortalRouter />
        {!this.props.isUserPortalDataFetched && (
          <LoaderFullScreen tip="Trwa logowanie..." />
        )}
        {!isCookiesAccepted && !isCookieInfoHidden && (
          <SuspenseWrapper>
            <CookiesInfo
              handleSave={this.handleCookiesSave}
              _name="CookiesInfo"
            />
          </SuspenseWrapper>
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  showMessage: globalSelectors.showMessage(state),
  message: globalSelectors.message(state),
  messageType: globalSelectors.messageType(state),
  globalSettings: globalSelectors.getGlobalSettings(state),
  languagesFetching: state.global.get('languagesFetching'),
  translationFetching: state.global.get('translationFetching'),
  areDistrictsFetched: districtSelectors.areDistrictsFetched(state),
  areDistrictsBeingFetched: districtSelectors.areDistrictsBeingFetched(state),
  userData: userSelectors.userData(state),
  isUserPortalDataFetched: userSelectors.isUserPortalDataFetched(state),
  isApiAvailable: globalSelectors.getApiAvailability(state),
  defaultApiFetching: state.global.get('defaultApiFetching'),
  mapPortalLoader: globalSelectors.getIsMapPortalLoading(state)
});

const mapDispatchToProps = {
  confirmLoginUser,
  fetchGlobalSettings: params =>
    globalApi.endpoints.fetchGlobalSettings.initiate(params),
  setScalesDenominators,
  hideError,
  hideSuccess,
  setRenderedComponents,
  setMapPortalLoader
};

MainView.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  message: PropTypes.string,
  messageType: PropTypes.string.isRequired,
  languagesFetching: ResponseStatus,
  translationFetching: ResponseStatus,
  areDistrictsFetched: PropTypes.bool.isRequired,
  areDistrictsBeingFetched: PropTypes.bool.isRequired,
  isUserPortalDataFetched: PropTypes.bool.isRequired,
  userData: UserDataShape,
  confirmLoginUser: PropTypes.func.isRequired,
  fetchGlobalSettings: PropTypes.func.isRequired,
  setScalesDenominators: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  hideSuccess: PropTypes.func.isRequired,
  isApiAvailable: PropTypes.bool,
  defaultApiFetching: ResponseStatus,
  setRenderedComponents: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MainView));
