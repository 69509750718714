export const FETCH_LAYER_FORMS_BEGIN = 'FETCH_LAYER_FORMS_BEGIN';
export const FETCH_LAYER_FORMS_SUCCESS = 'FETCH_LAYER_FORMS_SUCCESS';
export const FETCH_LAYER_FORMS_FAILED = 'FETCH_LAYER_FORMS_FAILED';

export const EDIT_LAYER_FORM_SUCCESS = 'EDIT_LAYER_FORM_SUCCESS';

export const FETCH_ADMIN_LAYER_OBJECTS_BEGIN =
  'FETCH_ADMIN_LAYER_OBJECTS_BEGIN';
export const FETCH_ADMIN_LAYER_OBJECTS_SUCCESS =
  'FETCH_ADMIN_LAYER_OBJECTS_SUCCESS';
export const FETCH_ADMIN_LAYER_OBJECTS_FAILED =
  'FETCH_ADMIN_LAYER_OBJECTS_FAILED';

export const GET_ADMIN_LAYER_OBJECT_BEGIN = 'GET_ADMIN_LAYER_OBJECT_BEGIN';
export const GET_ADMIN_LAYER_OBJECT_SUCCESS = 'GET_ADMIN_LAYER_OBJECT_SUCCESS';
export const GET_ADMIN_LAYER_OBJECT_FAILED = 'GET_ADMIN_LAYER_OBJECT_FAILED';

export const UPDATE_ADMIN_LAYER_OBJECT_BEGIN =
  'UPDATE_ADMIN_LAYER_OBJECT_BEGIN';
export const UPDATE_ADMIN_LAYER_OBJECT_SUCCESS =
  'UPDATE_ADMIN_LAYER_OBJECT_SUCCESS';
export const UPDATE_ADMIN_LAYER_OBJECT_FAILED =
  'UPDATE_ADMIN_LAYER_OBJECT_FAILED';

export const OPTIONS_ADMIN_LAYER_OBJECTS_BEGIN =
  'OPTIONS_ADMIN_LAYER_OBJECTS_BEGIN';
export const OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS =
  'OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS';
export const OPTIONS_ADMIN_LAYER_OBJECTS_FAILED =
  'OPTIONS_ADMIN_LAYER_OBJECTS_FAILED';

export const GET_USER_FORMS_SUCCESS = 'GET_USER_FORMS_SUCCESS';

export const GET_USER_FORM_BEGIN = 'GET_USER_FORM_BEGIN';
export const GET_USER_FORM_SUCCESS = 'GET_USER_FORM_SUCCESS';
export const GET_USER_FORM_FAILED = 'GET_USER_FORM_FAILED';

export const FETCH_SUBSCRIBER_LAYER_OBJECTS_BEGIN =
  'FETCH_SUBSCRIBER_LAYER_OBJECTS_BEGIN';
export const FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS =
  'FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS';
export const FETCH_SUBSCRIBER_LAYER_OBJECTS_FAILED =
  'FETCH_SUBSCRIBER_LAYER_OBJECTS_FAILED';

export const OPTIONS_SUBSCRIBER_LAYER_OBJECTS_BEGIN =
  'OPTIONS_SUBSCRIBER_LAYER_OBJECTS_BEGIN';
export const OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS =
  'OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS';
export const OPTIONS_SUBSCRIBER_LAYER_OBJECTS_FAILED =
  'OPTIONS_SUBSCRIBER_LAYER_OBJECTS_FAILED';

export const GET_SUBSCRIBER_LAYER_OBJECT_BEGIN =
  'GET_SUBSCRIBER_LAYER_OBJECT_BEGIN';
export const GET_SUBSCRIBER_LAYER_OBJECT_SUCCESS =
  'GET_SUBSCRIBER_LAYER_OBJECT_SUCCESS';
export const GET_SUBSCRIBER_LAYER_OBJECT_FAILED =
  'GET_SUBSCRIBER_LAYER_OBJECT_FAILED';

export const UPDATE_SUBSCRIBER_LAYER_OBJECT_BEGIN =
  'UPDATE_SUBSCRIBER_LAYER_OBJECT_BEGIN';
export const UPDATE_SUBSCRIBER_LAYER_OBJECT_SUCCESS =
  'UPDATE_SUBSCRIBER_LAYER_OBJECT_SUCCESS';
export const UPDATE_SUBSCRIBER_LAYER_OBJECT_FAILED =
  'UPDATE_SUBSCRIBER_LAYER_OBJECT_FAILED';

export const DELETE_OBJECT_BEGIN = 'DELETE_OBJECT_BEGIN';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAILED = 'DELETE_OBJECT_FAILED';

export const DELETE_SUBSCRIBER_LAYER_OBJECT_BEGIN =
  'DELETE_SUBSCRIBER_LAYER_OBJECT_BEGIN';
export const DELETE_SUBSCRIBER_LAYER_OBJECT_SUCCESS =
  'DELETE_SUBSCRIBER_LAYER_OBJECT_SUCCESS';
export const DELETE_SUBSCRIBER_LAYER_OBJECT_FAILED =
  'DELETE_SUBSCRIBER_LAYER_OBJECT_FAILED';

export const DELETE_ADMIN_LAYER_OBJECT_BEGIN =
  'DELETE_ADMIN_LAYER_OBJECT_BEGIN';
export const DELETE_ADMIN_LAYER_OBJECT_SUCCESS =
  'DELETE_ADMIN_LAYER_OBJECT_SUCCESS';
export const DELETE_ADMIN_LAYER_OBJECT_FAILED =
  'DELETE_ADMIN_LAYER_OBJECT_FAILED';

export const GET_OBJECT_ATTACHMENTS = 'GET_OBJECT_ATTACHMENTS';
export const GET_OBJECT_ATTACHMENTS_BEGIN = 'GET_OBJECT_ATTACHMENTS_BEGIN';
export const GET_OBJECT_ATTACHMENTS_SUCCESS = 'GET_OBJECT_ATTACHMENTS_SUCCESS';
export const GET_OBJECT_ATTACHMENTS_FAILED = 'GET_OBJECT_ATTACHMENTS_FAILED';

export const ADD_OBJECT_ATTACHMENTS_BEGIN = 'ADD_OBJECT_ATTACHMENTS_BEGIN';
export const ADD_OBJECT_ATTACHMENTS_SUCCESS = 'ADD_OBJECT_ATTACHMENTS_SUCCESS';
export const ADD_OBJECT_ATTACHMENTS_FAILED = 'ADD_OBJECT_ATTACHMENTS_FAILED';

export const DELETE_OBJECT_ATTACHMENT_BEGIN = 'DELETE_OBJECT_ATTACHMENT_BEGIN';
export const DELETE_OBJECT_ATTACHMENT_SUCCESS =
  'DELETE_OBJECT_ATTACHMENT_SUCCESS';
export const DELETE_OBJECT_ATTACHMENT_FAILED =
  'DELETE_OBJECT_ATTACHMENT_FAILED';
export const UPDATE_OBJECT_ATTACHMENTS_BEGIN =
  'UPDATE_OBJECT_ATTACHMENTS_BEGIN';
export const UPDATE_OBJECT_ATTACHMENTS_SUCCESS =
  'UPDATE_OBJECT_ATTACHMENTS_SUCCESS';
