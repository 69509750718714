import { AxiosResponse } from 'axios';

import handleApiError from '@/utils/handleApiError';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import { showError } from '@/store/actions/globalActions';

import {
  fetchDistrictModelsDataSuccess,
  fetchDistrictSearchableModelsBegin,
  fetchDistrictSearchableModelsFailed,
  fetchDistrictSearchableModelsSuccess,
  fetchSearchableModelsBegin,
  fetchSearchableModelsFailed,
  fetchSearchableModelsSuccess,
  getAllModelsTranslationsSuccess,
  getModelsTranslationsBegin,
  getModelsTranslationsFailed,
  getModelsTranslationsSuccess
} from '@/store/actions/modelsActions';

import isNotFoundRequestError from '@/utils/isNotFoundRequestError';
import { parseResponseError } from '@/utils/lib';

import {
  EDistrictModelsTags,
  TDistrictModelsParams,
  TDistrictSearchableModelsParams,
  TDistrictTranslationModelsParams,
  TSpatialApiAttachmentsParams
} from '@/types/api-types/models';
import {
  TDistrictModelsResponseResponse,
  TDistrictSearchableModelsResponse,
  TDistrictTranslationModelsResponse,
  TSearchableModelsResponse,
  TSpatialApiAttachmentsResponse
} from '@/types/models';

export const modelsApi = createApi({
  reducerPath: 'modelsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EDistrictModelsTags.DISTRICT_MODELS,
    EDistrictModelsTags.TRANSLATION_MODELS,
    EDistrictModelsTags.ATTACHMENTS,
    EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS,
    EDistrictModelsTags.SEARCHABLE_MODELS
  ],
  endpoints: builder => ({
    fetchDistrictModelsData: builder.query<
      TDistrictModelsResponseResponse,
      TDistrictModelsParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/spatial_api/`
      }),
      transformResponse: async (
        response: Promise<TDistrictModelsResponseResponse['data']>,
        meta: AxiosResponse
      ) => {
        const data = await response;

        return { data: data, language: meta.headers['content-language'] };
      },
      forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
        if (endpointState) {
          const global = (state.global as unknown) as Map<string, string>;
          const endpointData = (endpointState?.data as unknown) as {
            [key: string]: string;
          };

          return endpointData.language !== global.get('language');
        }
        return false;
      },
      onQueryStarted: async (arg, { dispatch, getState, queryFulfilled }) => {
        try {
          const {
            data: { data: models }
          } = await queryFulfilled;

          dispatch(fetchDistrictModelsDataSuccess(models));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: (result, error, arg) => [
        {
          type: EDistrictModelsTags.DISTRICT_MODELS,
          id: arg.district
        }
      ]
    }),
    fetchDistrictTranslationModels: builder.query<
      TDistrictTranslationModelsResponse[],
      TDistrictTranslationModelsParams
    >({
      query: ({ mapPortalId }) => ({
        method: 'GET',
        url: `translation_model_field/`,
        params: mapPortalId ? { mapportal: mapPortalId } : {}
      }),
      onQueryStarted: async ({ mapPortalId }, { dispatch, queryFulfilled }) => {
        dispatch(getModelsTranslationsBegin());

        try {
          const { data } = await queryFulfilled;
          if (mapPortalId) {
            dispatch(getModelsTranslationsSuccess(mapPortalId, data));
          } else {
            dispatch(getAllModelsTranslationsSuccess(data));
          }
        } catch (error) {
          dispatch(getModelsTranslationsFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }) => ({
                type: EDistrictModelsTags.TRANSLATION_MODELS,
                id
              })),
              {
                type: EDistrictModelsTags.TRANSLATION_MODELS,
                id: EDistrictModelsTags.TRANSLATION_MODELS
              }
            ]
          : [
              {
                type: EDistrictModelsTags.TRANSLATION_MODELS,
                id: EDistrictModelsTags.TRANSLATION_MODELS
              }
            ]
    }),
    getSpatialApiAttachments: builder.query<
      TSpatialApiAttachmentsResponse[],
      TSpatialApiAttachmentsParams
    >({
      query: ({ district, model, objectId }) => ({
        method: 'GET',
        url: `${district}/spatial_api/${model}/${objectId}/attachments/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          if (isNotFoundRequestError(error)) {
            return;
          }

          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }: { id: number }) => ({
                type: EDistrictModelsTags.ATTACHMENTS,
                id
              })),
              {
                type: EDistrictModelsTags.ATTACHMENTS,
                id: EDistrictModelsTags.ATTACHMENTS
              }
            ]
          : [
              {
                type: EDistrictModelsTags.ATTACHMENTS,
                id: EDistrictModelsTags.ATTACHMENTS
              }
            ]
    }),
    fetchDistrictSearchableModels: builder.query<
      TDistrictSearchableModelsResponse[],
      TDistrictSearchableModelsParams
    >({
      query: ({ district, withoutEmpty = false }) => ({
        method: 'GET',
        url: `${district}/searchable_models/${
          withoutEmpty ? '?without_empty=1' : ''
        }`
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        dispatch(fetchDistrictSearchableModelsBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDistrictSearchableModelsSuccess(district, data));
        } catch (error) {
          const errInfo = parseResponseError(error);
          dispatch(fetchDistrictSearchableModelsFailed());

          dispatch(
            showError(
              errInfo
                ? errInfo
                : 'Wystąpił błąd podczas pobierania listy dostępnych modeli.'
            )
          );
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }: { id: number }) => ({
                type: EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS,
                id
              })),
              {
                type: EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS,
                id: EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS
              }
            ]
          : [
              {
                type: EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS,
                id: EDistrictModelsTags.DISTRICT_SEARCHABLE_MODELS
              }
            ]
    }),
    fetchSearchableModels: builder.query<TSearchableModelsResponse[], null>({
      query: () => ({
        method: 'GET',
        url: 'searchable_models/'
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchSearchableModelsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(fetchSearchableModelsSuccess(data));
        } catch (error) {
          const errInfo = parseResponseError(error);
          dispatch(fetchSearchableModelsFailed());

          dispatch(
            showError(
              errInfo
                ? errInfo
                : 'Wystąpił błąd podczas pobierania listy dostępnych modeli.'
            )
          );
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }: { id: number }) => ({
                type: EDistrictModelsTags.SEARCHABLE_MODELS,
                id
              })),
              {
                type: EDistrictModelsTags.SEARCHABLE_MODELS,
                id: EDistrictModelsTags.SEARCHABLE_MODELS
              }
            ]
          : [
              {
                type: EDistrictModelsTags.SEARCHABLE_MODELS,
                id: EDistrictModelsTags.SEARCHABLE_MODELS
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useFetchDistrictModelsDataQuery,
  useLazyFetchDistrictModelsDataQuery,
  useLazyFetchDistrictTranslationModelsQuery,
  useGetSpatialApiAttachmentsQuery,
  useLazyGetSpatialApiAttachmentsQuery,
  useLazyFetchDistrictSearchableModelsQuery,
  useLazyFetchSearchableModelsQuery
} = modelsApi;
