import { TAdminLayerForm } from '../responses/edit-data';

export const enum EEditDataTags {
  USER_FORMS = 'userForms',
  LAYER_FORMS = 'layerForms'
}

export type TEditDataParams = {
  district: string;
  mapPortalCompositionId?: number;
};

export type TLayerFormsParams = {
  district: string;
  query?: {
    name: string;
    layer: number;
    group: number;
    subscription_group: number;
  };
  pageSize: number;
  page: number;
};

export type TUpdateLayerFormParams = {
  district: string;
  id: number;
  data: Partial<TAdminLayerForm>;
};

export type TDeleteLayerFormParams = Omit<TUpdateLayerFormParams, 'data'>;
