import handleApiError from '@/utils/handleApiError';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  fetchAllGroupsBegin,
  fetchAllGroupsSuccess
} from '@/store/actions/groupsActions';
import { fetchDistrictRolesFailed } from '@/store/actions/districtActions';

import { TAllGroupsResponse } from '@/types/groups';
import { EGroupsTags, TAllGroupsParams } from '@/types/api-types/groups';

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EGroupsTags.ALL_GROUPS],
  endpoints: builder => ({
    fetchAllGroups: builder.query<TAllGroupsResponse, TAllGroupsParams>({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/district_group/`
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        dispatch(fetchAllGroupsBegin());

        try {
          const {
            data: { results }
          } = await queryFulfilled;

          dispatch(fetchAllGroupsSuccess({ district, groups: results }));
        } catch (error) {
          dispatch(fetchDistrictRolesFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.results.map(({ id }: { id: number }) => ({
                type: EGroupsTags.ALL_GROUPS,
                id
              })),
              {
                type: EGroupsTags.ALL_GROUPS,
                id: EGroupsTags.ALL_GROUPS
              }
            ]
          : [
              {
                type: EGroupsTags.ALL_GROUPS,
                id: EGroupsTags.ALL_GROUPS
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const { useLazyFetchAllGroupsQuery } = groupsApi;
