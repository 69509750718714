export const enum EUserTags {
  ADMIN_USER_ROLES = 'adminUserRoles',
  USER_BY_ID = 'userById',
  GLOBAL_USERS_DATA = 'globalUsersData'
}

export type TAdminUserRolesParams = {
  district: string;
};

export type TUserByIdParams = {
  district: string;
  id: number;
};

export type TGlobalAuthUserParams = {
  userId: string;
};
