export enum EDistrictModelsTags {
  DISTRICT_MODELS = 'districtModels',
  TRANSLATION_MODELS = 'translationModels',
  ATTACHMENTS = 'attachments',
  DISTRICT_SEARCHABLE_MODELS = 'districtSearchableModels',
  SEARCHABLE_MODELS = 'searchableModels'
}

export type TDistrictModelsParams = {
  district: string;
};

export type TDistrictTranslationModelsParams = {
  mapPortalId: number;
};

export type TSpatialApiAttachmentsParams = {
  district: string;
  model: string;
  objectId: number;
};

export type TDistrictSearchableModelsParams = {
  district: string;
  withoutEmpty?: boolean;
};
