import axios from 'axios';
import { parseString as xmlToJs } from 'xml2js';

import { TObject } from '@/types/common-types';
import { TResponse } from './types';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = '';

const getWmtsCapabilities = (url: string): TObject =>
  new Promise((resolve, reject) => {
    const params = {
      service: 'WMTS',
      request: 'GetCapabilities'
    };

    axiosInstance
      .get(url, { params, crossDomain: true })
      .then((response: TResponse) =>
        xmlToJs(response.data, (err, result) =>
          err ? reject(err) : resolve(result)
        )
      )
      .catch(reject);
  });

export default getWmtsCapabilities;
