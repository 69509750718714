import axios from 'axios';

import { reportSelectors } from '../selectors';

import {
  FETCH_ALL_REPORTS_BEGIN,
  FETCH_ALL_REPORTS_FAILED,
  FETCH_ALL_REPORTS_SUCCESS,
  FETCH_OPTIONS_REPORTS_BEGIN,
  FETCH_OPTIONS_REPORTS_SUCCESS,
  FETCH_OPTIONS_REPORTS_FAILED,
  FETCH_SINGLE_REPORT_BEGIN,
  FETCH_SINGLE_REPORT_FAILED,
  FETCH_SINGLE_REPORT_SUCCESS,
  FETCH_REPORT_CATEGORIES_BEGIN,
  FETCH_REPORT_CATEGORIES_SUCCESS,
  FETCH_REPORT_CATEGORIES_FAILED,
  FETCH_REPORT_SUBCATEGORIES_FAILED,
  FETCH_REPORT_SUBCATEGORIES_SUCCESS,
  FETCH_REPORT_SUBCATEGORIES_BEGIN,
  UPDATE_REPORT_BEGIN,
  UPDATE_REPORT_END,
  DELETE_REPORT_BEGIN,
  DELETE_REPORT_END,
  FETCH_REPORT_STATUSES_BEGIN,
  FETCH_REPORT_STATUSES_SUCCESS,
  FETCH_REPORT_STATUSES_FAILED,
  SET_REPORT_POINT,
  CLEAR_REPORT_POINT,
  CREATE_REPORT_BEGIN,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILED,
  UPLOAD_REPORT_ATTACHMENT_BEGIN,
  UPLOAD_REPORT_ATTACHMENT_SUCCESS,
  UPLOAD_REPORT_ATTACHMENT_FAILED,
  CREATE_REPORT_COMMENT_BEGIN,
  CREATE_REPORT_COMMENT_SUCCESS,
  CREATE_REPORT_COMMENT_FAILED,
  FETCH_REPORT_COMMENT_BEGIN,
  FETCH_REPORT_COMMENT_SUCCESS,
  FETCH_REPORT_COMMENT_FAILED,
  FETCH_REPORT_HISTORY_BEGIN,
  FETCH_REPORT_HISTORY_SUCCESS,
  UPDATE_REPORT_COMMENT_BEGIN,
  UPDATE_REPORT_COMMENT_SUCCESS,
  UPDATE_REPORT_COMMENT_FAILED,
  FETCH_DISTRICT_REPORTS_BEGIN,
  FETCH_DISTRICT_REPORTS_SUCCESS,
  FETCH_DISTRICT_REPORTS_FAILED,
  DISPLAY_REPORTS,
  FETCH_SINGLE_REPORT_CATEGORY_BEGIN,
  FETCH_SINGLE_REPORT_CATEGORY_SUCCESS,
  FETCH_SINGLE_REPORT_CATEGORY_FAILED,
  FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN,
  FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS,
  FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED,
  FETCH_SINGLE_REPORT_STATUS_BEGIN,
  FETCH_SINGLE_REPORT_STATUS_SUCCESS,
  FETCH_SINGLE_REPORT_STATUS_FAILED,
  DELETE_REPORT_VOTE_BEGIN,
  DELETE_REPORT_VOTE_SUCCESS,
  DELETE_REPORT_VOTE_FAILED,
  ADD_REPORT_VOTE_FAILED,
  ADD_REPORT_VOTE_SUCCESS,
  ADD_REPORT_VOTE_BEGIN,
  DELETE_REPORT_COMMENT_BEGIN,
  DELETE_REPORT_COMMENT_SUCCESS,
  DELETE_REPORT_COMMENT_FAILED,
  FETCH_REPORT_HISTORY_FAILED,
  DELETE_REPORT_OWNER_BEGIN,
  DELETE_REPORT_OWNER_SUCCESS,
  DELETE_REPORT_OWNER_FAILED,
  DELETE_REPORT_ATTACHMENT_FAILED,
  DELETE_REPORT_ATTACHMENT_SUCCESS,
  DELETE_REPORT_ATTACHMENT,
  CREATE_ASSIGN_TO_CATEGORY,
  CREATE_ASSIGN_TO_CATEGORY_SUCCESS,
  CREATE_ASSIGN_TO_CATEGORY_FAILED,
  FETCH_USER_SUBSCRIPTIONS,
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  FETCH_USER_SUBSCRIPTIONS_FAILED,
  DELETE_ASSIGN_TO_CATEGORY,
  DELETE_ASSIGN_TO_CATEGORY_SUCCESS,
  DELETE_ASSIGN_TO_CATEGORY_FAILED,
  UPDATE_ASSIGN_TO_CATEGORY_SUCCESS,
  UPDATE_ASSIGN_TO_CATEGORY_FAILED,
  UPDATE_ASSIGN_TO_CATEGORY,
  SET_SINGLE_REPORT,
  DELETE_CATEGORY_FROM_DISTRICT_BEGIN,
  DELETE_CATEGORY_FROM_DISTRICT_SUCCESS,
  DELETE_CATEGORY_FROM_DISTRICT_FAILED,
  EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_BEGIN,
  EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_SUCCESS,
  EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_FAILED,
  CREATE_ADMIN_SUBCATEGORY_BEGIN,
  CREATE_ADMIN_SUBCATEGORY_SUCCESS,
  CREATE_ADMIN_SUBCATEGORY_FAILED,
  CREATE_ADMIN_CATEGORY_BEGIN,
  CREATE_ADMIN_CATEGORY_SUCCESS,
  CREATE_ADMIN_CATEGORY_FAILED,
  EDIT_ADMIN_SUBCATEGORY_BEGIN,
  EDIT_ADMIN_SUBCATEGORY_FAILED,
  EDIT_ADMIN_SUBCATEGORY_SUCCESS,
  DELETE_ADMIN_SUBCATEGORY_BEGIN,
  DELETE_ADMIN_SUBCATEGORY_FAILED,
  DELETE_ADMIN_SUBCATEGORY_SUCCESS,
  FETCH_ALL_SUBCATEGORIES_BEGIN,
  FETCH_ALL_SUBCATEGORIES_SUCCESS,
  FETCH_ALL_SUBCATEGORIES_FAILED,
  FETCH_ADMIN_CATEGORIES_BEGIN,
  FETCH_ADMIN_CATEGORIES_SUCCESS,
  FETCH_ADMIN_CATEGORIES_FAILED,
  UPDATE_ADMIN_SUBCATEGORIES,
  SET_STATUS_FILTER,
  SET_CATEGORY_FILTER,
  ZOOM_TO_CATEGORY,
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILED,
  FETCH_REPORT_DATA_BEGIN,
  ENABLE_FILTERS,
  GET_DEFECTS_SUBSCRIPTIONS_BEGIN,
  GET_DEFECTS_SUBSCRIPTIONS_FAILED,
  GET_DEFECTS_SUBSCRIPTIONS_SUCCESS,
  CREATE_REPORT_STATUS_BEGIN,
  CREATE_REPORT_STATUS_SUCCESS,
  CREATE_REPORT_STATUS_FAILED,
  DELETE_REPORT_STATUS_BEGIN,
  DELETE_REPORT_STATUS_SUCCESS,
  DELETE_REPORT_STATUS_FAILED,
  EDIT_REPORT_STATUS_BEGIN,
  EDIT_REPORT_STATUS_SUCCESS,
  EDIT_REPORT_STATUS_FAILED,
  UPDATE_REPORT_CONFIGS,
  CREATE_REPORT_CONFIG_BEGIN,
  CREATE_REPORT_CONFIG_FAILED,
  CREATE_REPORT_CONFIG_SUCCESS,
  UPDATE_REPORT_CONFIG_BEGIN,
  UPDATE_REPORT_CONFIG_FAILED,
  UPDATE_REPORT_CONFIG_SUCCESS,
  FETCH_REPORT_CATEGORY_CONFIG_BEIGN,
  FETCH_REPORT_CATEGORY_CONFIG_SUCCESS,
  FETCH_REPORT_CATEGORY_CONFIG_FAILED,
  FETCH_REPORT_STATUS_CONFIG_BEIGN,
  FETCH_REPORT_STATUS_CONFIG_SUCCESS,
  FETCH_REPORT_STATUS_CONFIG_FAILED,
  DELETE_REPORT_CONFIG_BEGIN,
  DELETE_REPORT_CONFIG_SUCCESS,
  DELETE_REPORT_CONFIG_FAILED,
  CRAETE_REPORT_CATEGORY_COLOR_BEGIN,
  CRAETE_REPORT_CATEGORY_COLOR_SUCCESS,
  CRAETE_REPORT_CATEGORY_COLOR_FAILED,
  CRAETE_REPORT_STATUS_COLOR_BEGIN,
  CRAETE_REPORT_STATUS_COLOR_FAILED,
  CREATE_REPORT_STATUS_COLOR_SUCCESS,
  UPDATE_REPORT_STATUS_COLOR_BEGIN,
  UPDATE_REPORT_STATUS_COLOR_FAILED,
  UPDATE_REPORT_STATUS_COLOR_SUCCESS,
  UPDATE_REPORT_CATEGORY_COLOR_BEGIN,
  UPDATE_REPORT_CATEGORY_COLOR_SUCCESS,
  UPDATE_REPORT_CATEGORY_COLOR_FAILED,
  FETCH_REPORT_SINGLE_CONFIG_BEGIN,
  FETCH_REPORT_SINGLE_CONFIG_SUCCESS,
  FETCH_REPORT_SINGLE_CONFIG_FAILED,
  CLEAR_REPORT_COLORS,
  GET_TYPE_REPORTS_REQUEST,
  GET_TYPE_REPORTS_SUCCESS,
  GET_TYPE_REPORTS_FAILED,
  GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST,
  GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  GET_DEFAULT_STATUS_TYPE_REPORT_FAILED,
  UPDATE_DEFAULT_STATUS_TYPE_REPORT_REQUEST,
  UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  UPDATE_DEFAULT_STATUS_TYPE_REPORT_FAILED,
  SHOW_TYPE_REPORT,
  CREATE_TYPE_REPORT_FAILED,
  CREATE_TYPE_REPORT_SUCCESS,
  CREATE_TYPE_REPORT_REQUEST,
  DELETE_TYPE_REPORT_REQUEST,
  DELETE_TYPE_REPORT_SUCCESS,
  DELETE_TYPE_REPORT_FAILED,
  UPDATE_TYPE_REPORT_REQUEST,
  UPDATE_TYPE_REPORT_SUCCESS,
  UPDATE_TYPE_REPORT_FAILED,
  CREATE_UNREGISTERED_REPORT_BEGIN,
  CREATE_UNREGISTERED_REPORT_SUCCESS,
  CREATE_UNREGISTERED_REPORT_FAILED,
  FETCH_DEFECT_CONFIGURATION_SUCCESS,
  UPDATE_DEFECT_CONFIGURATION_STARTED,
  UPDATE_DEFECT_CONFIGURATION_SUCCESS,
  UPDATE_DEFECT_CONFIGURATION_FAILED,
  GET_CATEGORY_ATTRIBUTES_STARTED,
  GET_CATEGORY_ATTRIBUTES_SUCCESS,
  GET_CATEGORY_ATTRIBUTES_FAILED,
  ADD_CATEGORY_ATTRIBUTE_STARTED,
  ADD_CATEGORY_ATTRIBUTE_SUCCESS,
  ADD_CATEGORY_ATTRIBUTE_FAILED,
  DELETE_CATEGORY_ATTRIBUTES_FAILED,
  UPDATE_CATEGORY_ATTRIBUTES_STARTED,
  UPDATE_CATEGORY_ATTRIBUTES_SUCCESS,
  UPDATE_CATEGORY_ATTRIBUTES_FAILED,
  DELETE_CATEGORY_ATTRIBUTES_SUCCESS,
  DELETE_CATEGORY_ATTRIBUTES_STARTED,
  CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED,
  CLEAR_CATEGORY_ATTRIBUTES
} from '../constants/reportsActionTypes';

import { reportApi } from '../api/report';

import { showError, showSuccess } from './globalActions';

import { parseResponseError } from '../../utils/lib';
import { PAGE_SIZE } from '../../utils/constants';

export const fetchAllReportsBegin = () => ({
  type: FETCH_ALL_REPORTS_BEGIN
});

export const fetchAllReportsSuccess = reports => ({
  type: FETCH_ALL_REPORTS_SUCCESS,
  reports
});

export const fetchAllReportsFailed = () => ({
  type: FETCH_ALL_REPORTS_FAILED
});

export const fetchOptionsReportsBegin = () => ({
  type: FETCH_OPTIONS_REPORTS_BEGIN
});

export const fetchOptionsReportsSuccess = optionsReports => ({
  type: FETCH_OPTIONS_REPORTS_SUCCESS,
  optionsReports
});

export const fetchOptionsReportsFailed = () => ({
  type: FETCH_OPTIONS_REPORTS_FAILED
});

export const fetchSingleReportBegin = () => ({
  type: FETCH_SINGLE_REPORT_BEGIN
});

export const fetchSingleReportSuccess = singleReport => ({
  type: FETCH_SINGLE_REPORT_SUCCESS,
  singleReport
});

export const fetchSingleReportFailed = () => ({
  type: FETCH_SINGLE_REPORT_FAILED
});

export const fetchSingleReport = (district, id, admin = false) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSingleReportBegin());

    axios
      .get(
        admin
          ? `${district}/defect_subscriber/${id}/`
          : `${district}/defect/${id}/`,
        {
          withCredentials: true
        }
      )
      .then(r => {
        dispatch(fetchSingleReportSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchSingleReportFailed());
        reject(err);
      });
  });

// RTQ
export const fetchReportCategoriesBegin = () => ({
  type: FETCH_REPORT_CATEGORIES_BEGIN
});

export const fetchReportCategoriesSuccess = reportCategories => ({
  type: FETCH_REPORT_CATEGORIES_SUCCESS,
  reportCategories
});

export const fetchReportCategoriesFailed = () => ({
  type: FETCH_REPORT_CATEGORIES_FAILED
});

export const createAdminReportCategoryLoadingFinished = () => ({
  type: CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED
});

export const fetchReportSubcategoriesBegin = () => ({
  type: FETCH_REPORT_SUBCATEGORIES_BEGIN
});

export const fetchReportSubcategoriesSuccess = reportSubcategories => ({
  type: FETCH_REPORT_SUBCATEGORIES_SUCCESS,
  reportSubcategories
});

export const fetchReportSubcategoriesFailed = () => ({
  type: FETCH_REPORT_SUBCATEGORIES_FAILED
});

export const updateReportBegin = () => ({
  type: UPDATE_REPORT_BEGIN
});

export const updateReportEnd = () => ({
  type: UPDATE_REPORT_END
});

export const updateReport = (district, id, data, admin = false) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateReportBegin());

    axios
      .patch(
        admin
          ? `${district}/defect_subscriber/${id}/`
          : `${district}/defect_owner/${id}/`,
        data
      )
      .then(r => {
        dispatch(updateReportEnd());
        resolve(r);
      })
      .catch(err => {
        dispatch(updateReportEnd());
        reject(err);
      });
  });

export const deleteReportBegin = () => ({
  type: DELETE_REPORT_BEGIN
});

export const deleteReportEnd = () => ({
  type: DELETE_REPORT_END
});

export const deleteReport = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportBegin());

    axios
      .delete(`${district}/defect_subscriber/${id}/`)
      .then(r => {
        dispatch(deleteReportEnd());
        resolve(r);
      })
      .catch(err => {
        dispatch(deleteReportEnd());
        dispatch(showSuccess(parseResponseError(err)));
        reject(err);
      });
  });

export const fetchReportStatusesBegin = () => ({
  type: FETCH_REPORT_STATUSES_BEGIN
});

export const fetchReportStatusesSuccess = reportStatuses => ({
  type: FETCH_REPORT_STATUSES_SUCCESS,
  reportStatuses
});

export const fetchReportStatusesFailed = () => ({
  type: FETCH_REPORT_STATUSES_FAILED
});

export const createReportStatusBegin = () => ({
  type: CREATE_REPORT_STATUS_BEGIN
});

export const createReportStatusSuccess = () => ({
  type: CREATE_REPORT_STATUS_SUCCESS
});

export const createReportStatusFailed = () => ({
  type: CREATE_REPORT_STATUS_FAILED
});

export const createReportStatus = statusData => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportStatusBegin());

    axios
      .post(`defect_status_admin/`, statusData)
      .then(() => {
        dispatch(createReportStatusSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(
          showError(
            parseResponseError(err, {
              unique: 'Istnieje już status z tą nazwą!'
            })
          )
        );

        dispatch(createReportStatusFailed());
        reject(err);
      });
  });

export const deleteReportStatusBegin = () => ({
  type: DELETE_REPORT_STATUS_BEGIN
});

export const deleteReportStatusSuccess = () => ({
  type: DELETE_REPORT_STATUS_SUCCESS
});

export const deleteReportStatusFailed = () => ({
  type: DELETE_REPORT_STATUS_FAILED
});

export const deleteReportStatus = statusId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportStatusBegin());

    axios
      .delete(`defect_status_admin/${statusId}/`)
      .then(() => {
        dispatch(deleteReportStatusSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(deleteReportStatusFailed());
        reject(err);
      });
  });

export const editReportStatusBegin = () => ({
  type: EDIT_REPORT_STATUS_BEGIN
});

export const editReportStatusSuccess = () => ({
  type: EDIT_REPORT_STATUS_SUCCESS
});

export const editReportStatusFailed = () => ({
  type: EDIT_REPORT_STATUS_FAILED
});

export const editReportStatus = data => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportStatusBegin());

    axios
      .patch(`defect_status_admin/${data.id}/`, {
        name: data.name,
        type: data.type
      })
      .then(() => {
        dispatch(editReportStatusSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(
          showError(
            parseResponseError(err, {
              unique: 'Istnieje już status z tą nazwą!'
            })
          )
        );
        dispatch(editReportStatusFailed());
        reject(err);
      });
  });

export const setReportPoint = reportPoint => ({
  type: SET_REPORT_POINT,
  reportPoint
});

export const clearReportPoint = () => ({
  type: CLEAR_REPORT_POINT
});

export const createReportBegin = () => ({
  type: CREATE_REPORT_BEGIN
});

export const createReportSuccess = () => ({
  type: CREATE_REPORT_SUCCESS
});

export const createReportFailed = () => ({
  type: CREATE_REPORT_FAILED
});

export const createReport = (prefix, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportBegin());

    axios
      .post(`${prefix}/defect_owner/`, data)
      .then(r => {
        dispatch(createReportSuccess());
        resolve(r.data);
      })
      .catch(err => {
        dispatch(createReportFailed());
        reject(err);
      });
  });

export const createUnregisteredReportBegin = () => ({
  type: CREATE_UNREGISTERED_REPORT_BEGIN
});

export const createUnregisteredReportSuccess = () => ({
  type: CREATE_UNREGISTERED_REPORT_SUCCESS
});

export const createUnregisteredReportFailed = () => ({
  type: CREATE_UNREGISTERED_REPORT_FAILED
});

export const createUnregisteredReport = (prefix, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createUnregisteredReportBegin());

    axios
      .post(`${prefix}/defect_unregistered_owner/`, data)
      .then(r => {
        dispatch(createUnregisteredReportSuccess());
        resolve(r.data);
      })
      .catch(err => {
        dispatch(createUnregisteredReportFailed());
        reject(err);
      });
  });

export const uploadReportAttachmentBegin = () => ({
  type: UPLOAD_REPORT_ATTACHMENT_BEGIN
});

export const uploadReportAttachmentSuccess = () => ({
  type: UPLOAD_REPORT_ATTACHMENT_SUCCESS
});

export const uploadReportAttachmentFailed = () => ({
  type: UPLOAD_REPORT_ATTACHMENT_FAILED
});

export const uploadReportAttachment = ({ data, district, id }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(uploadReportAttachmentBegin());

    const formData = new FormData();
    formData.append('file', data, data.name);

    axios
      .post(`${district}/defect/${id}/attachment/`, formData)
      .then(r => {
        dispatch(uploadReportAttachmentSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(showError(parseResponseError(err)));
        dispatch(uploadReportAttachmentFailed());
        reject();
      });
  });

export const deleteReportAttachmentBegin = () => ({
  type: DELETE_REPORT_ATTACHMENT
});

export const deleteReportAttachmentSuccess = () => ({
  type: DELETE_REPORT_ATTACHMENT_SUCCESS
});

export const deleteReportAttachmentFailed = () => ({
  type: DELETE_REPORT_ATTACHMENT_FAILED
});

export const deleteReportAttachment = uuid => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportAttachmentBegin());

    axios
      .delete(`defect_attachment/${uuid}/`)
      .then(r => {
        dispatch(deleteReportAttachmentSuccess());
        dispatch(showSuccess('Pomyślnie usunięto załącznik!'));
        resolve(r);
      })
      .catch(() => {
        dispatch(deleteReportAttachmentFailed());
        dispatch(showError('Wystąpił błąd podczas usuwania załacznika!'));
        reject();
      });
  });

export const createReportCommentBegin = () => ({
  type: CREATE_REPORT_COMMENT_BEGIN
});

export const createReportCommentSuccess = () => ({
  type: CREATE_REPORT_COMMENT_SUCCESS
});

export const createReportCommentFailed = () => ({
  type: CREATE_REPORT_COMMENT_FAILED
});

export const createReportComment = (id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportCommentBegin());

    axios
      .post(`defect_comment/${id}/`, data)
      .then(r => {
        dispatch(createReportCommentSuccess(r.data));
        resolve();
      })
      .catch(() => {
        dispatch(createReportCommentFailed());
        reject();
      });
  });

export const fetchReportCommentsBegin = () => ({
  type: FETCH_REPORT_COMMENT_BEGIN
});

export const fetchReportCommentsSuccess = reportComments => ({
  type: FETCH_REPORT_COMMENT_SUCCESS,
  reportComments
});

export const fetchReportCommentsFailed = () => ({
  type: FETCH_REPORT_COMMENT_FAILED
});

export const fetchReportComments = (id, page = 1) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchReportCommentsBegin());

    axios
      .get(`defect_comment/${id}/`, {
        params: {
          page_size: PAGE_SIZE,
          page
        }
      })
      .then(r => {
        dispatch(fetchReportCommentsSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchReportCommentsFailed());
        reject(err);
      });
  });

export const fetchReportHistoryBegin = () => ({
  type: FETCH_REPORT_HISTORY_BEGIN
});

export const fetchReportHistorySuccess = reportHistory => ({
  type: FETCH_REPORT_HISTORY_SUCCESS,
  reportHistory
});

export const fetchReportHistoryFailed = () => ({
  type: FETCH_REPORT_HISTORY_FAILED
});

export const fetchReportHistory = (id, page = 1) => dispatch => {
  dispatch(fetchReportHistoryBegin());

  return axios
    .get(`defect_history/${id}/?page_size=5&page=${page}`)
    .then(r => {
      dispatch(fetchReportHistorySuccess(r.data));
    })
    .catch(() => {
      dispatch(fetchReportHistoryFailed());
    });
};

export const updateReportCommentBegin = () => ({
  type: UPDATE_REPORT_COMMENT_BEGIN
});

export const updateReportCommentSuccess = () => ({
  type: UPDATE_REPORT_COMMENT_SUCCESS
});

export const updateReportCommentFailed = () => ({
  type: UPDATE_REPORT_COMMENT_FAILED
});

export const updateReportComment = (reportId, commentId, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateReportCommentBegin());

    return axios
      .patch(`defect_comment/${reportId}/${commentId}/`, data)
      .then(r => {
        dispatch(updateReportCommentSuccess(r.data));
        dispatch(showSuccess('Komentarz został zaktualizowany.'));
        resolve(r);
      })
      .catch(err => {
        dispatch(updateReportCommentFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteReportOwnerBegin = () => ({
  type: DELETE_REPORT_OWNER_BEGIN
});

export const deleteReportOwnerSuccess = () => ({
  type: DELETE_REPORT_OWNER_SUCCESS
});

export const deleteReportOwnerFailed = () => ({
  type: DELETE_REPORT_OWNER_FAILED
});

export const deleteReportOwner = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportOwnerBegin());
    axios
      .delete(`${district}/defect_owner/${id}/`)
      .then(() => {
        dispatch(deleteReportOwnerSuccess());
        resolve();
      })
      .catch(() => {
        dispatch(deleteReportOwnerFailed());
        reject();
      });
  });

//RTQ
export const fetchDistrictReportsBegin = () => ({
  type: FETCH_DISTRICT_REPORTS_BEGIN
});

export const fetchDistrictReportsSuccess = districtReports => ({
  type: FETCH_DISTRICT_REPORTS_SUCCESS,
  districtReports
});

export const fetchDistrictReportsFailed = () => ({
  type: FETCH_DISTRICT_REPORTS_FAILED
});

export const fetchReportDataBegin = () => ({
  type: FETCH_REPORT_DATA_BEGIN
});

export const fetchReportDataSuccess = () => ({
  type: FETCH_REPORT_DATA_SUCCESS
});

export const fetchReportDataFailed = () => ({
  type: FETCH_REPORT_DATA_FAILED
});

export const fetchReportData = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchReportDataBegin());

    return axios
      .get(`${district}/defect_owner/`)
      .then(r => {
        dispatch(fetchReportDataSuccess());
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchReportDataFailed());
        reject(err);
      });
  });

export const setDisplayReports = displayReports => ({
  type: DISPLAY_REPORTS,
  displayReports
});

export const fetchSingleReportCategoryBegin = () => ({
  type: FETCH_SINGLE_REPORT_CATEGORY_BEGIN
});

export const fetchSingleReportCategorySuccess = () => ({
  type: FETCH_SINGLE_REPORT_CATEGORY_SUCCESS
});

export const fetchSingleReportCategoryFailed = () => ({
  type: FETCH_SINGLE_REPORT_CATEGORY_FAILED
});

export const fetchSingleReportCategory = (district, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSingleReportCategoryBegin());

    axios
      .get(`${district}/defect_category/${id}/`)
      .then(r => {
        dispatch(fetchSingleReportCategorySuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchSingleReportCategoryFailed());
        reject(err);
      });
  });

export const fetchSingleReportSubcategoryBegin = () => ({
  type: FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN
});

export const fetchSingleReportSubcategorySuccess = () => ({
  type: FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS
});

export const fetchSingleReportSubcategoryFailed = () => ({
  type: FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED
});

export const fetchSingleReportSubcategory = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSingleReportSubcategoryBegin());

    axios
      .get(`${prefix}/defect_subcategory/${id}/`)
      .then(r => {
        dispatch(fetchSingleReportSubcategorySuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchSingleReportSubcategoryFailed());
        reject(err);
      });
  });

export const fetchSingleReportStatusBegin = () => ({
  type: FETCH_SINGLE_REPORT_STATUS_BEGIN
});

export const fetchSingleReportStatusSuccess = () => ({
  type: FETCH_SINGLE_REPORT_STATUS_SUCCESS
});

export const fetchSingleReportStatusFailed = () => ({
  type: FETCH_SINGLE_REPORT_STATUS_FAILED
});

export const fetchSingleReportStatus = id => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSingleReportStatusBegin());

    axios
      .get(`/defect_status/${id}/`)
      .then(r => {
        dispatch(fetchSingleReportStatusSuccess());
        resolve(r);
      })
      .catch(err => {
        dispatch(fetchSingleReportStatusFailed());
        reject(err);
      });
  });

export const addReportVoteBegin = () => ({
  type: ADD_REPORT_VOTE_BEGIN
});

export const addReportVoteSuccess = () => ({
  type: ADD_REPORT_VOTE_SUCCESS
});

export const addReportVoteFailed = () => ({
  type: ADD_REPORT_VOTE_FAILED
});

export const addReportVote = id => dispatch => {
  dispatch(addReportVoteBegin());

  return axios
    .post(`/defect_vote/${id}/`, null, { withCredentials: true })
    .then(r => {
      dispatch(addReportVoteSuccess());
    })
    .catch(() => {
      dispatch(addReportVoteFailed());
    });
};

export const deleteReportVoteBegin = () => ({
  type: DELETE_REPORT_VOTE_BEGIN
});

export const deleteReportVoteSuccess = () => ({
  type: DELETE_REPORT_VOTE_SUCCESS
});

export const deleteReportVoteFailed = () => ({
  type: DELETE_REPORT_VOTE_FAILED
});

export const deleteReportVote = id => dispatch => {
  dispatch(deleteReportVoteBegin());

  return axios
    .delete(`/defect_vote/${id}/`, { withCredentials: true })
    .then(r => {
      dispatch(deleteReportVoteSuccess());
    })
    .catch(() => {
      dispatch(deleteReportVoteFailed());
    });
};

export const deleteReportCommentBegin = () => ({
  type: DELETE_REPORT_COMMENT_BEGIN
});

export const deleteReportCommentSuccess = () => ({
  type: DELETE_REPORT_COMMENT_SUCCESS
});

export const deleteReportCommentFailed = () => ({
  type: DELETE_REPORT_COMMENT_FAILED
});

export const deleteReportComment = (reportId, commentId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportCommentBegin());

    axios
      .delete(`/defect_comment/${reportId}/${commentId}/`)
      .then(r => {
        dispatch(deleteReportCommentSuccess());
        resolve();
      })
      .catch(() => {
        dispatch(deleteReportCommentFailed());
        reject();
      });
  });

export const createAssignToCategoryBegin = () => ({
  type: CREATE_ASSIGN_TO_CATEGORY
});

export const createAssignToCategorySuccess = (userId, subscription) => ({
  type: CREATE_ASSIGN_TO_CATEGORY_SUCCESS,
  userId,
  subscription
});

export const createAssignToCategoryFailed = () => ({
  type: CREATE_ASSIGN_TO_CATEGORY_FAILED
});

export const createAssignToCategory = (district, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createAssignToCategoryBegin());

    axios
      .post(`/${district}/defect_subscription/`, data)
      .then(r => {
        dispatch(createAssignToCategorySuccess(data.user, r.data));
        dispatch(showSuccess('Użytkownik został przypisany do kategorii'));
        resolve(r.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err, {
          unique:
            'Użytkownik ma subskrypcje do wybranej kategorii i subkategorii.'
        });

        dispatch(createAssignToCategoryFailed());
        dispatch(showError(errInfo));
        reject();
      });
  });

export const getDefectsSubscriptionsBegin = () => ({
  type: GET_DEFECTS_SUBSCRIPTIONS_BEGIN
});

export const getDefectsSubscriptionsSuccess = () => ({
  type: GET_DEFECTS_SUBSCRIPTIONS_SUCCESS
});

export const getDefectsSubscriptionsFailed = () => ({
  type: GET_DEFECTS_SUBSCRIPTIONS_FAILED
});

export const getDefectsSubscriptions = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getDefectsSubscriptionsBegin());

    axios
      .get(`/${district}/defect_subscription/`)
      .then(r => {
        dispatch(getDefectsSubscriptionsSuccess());
        resolve(r?.data);
      })
      .catch(err => {
        dispatch(getDefectsSubscriptionsFailed());
        reject(err);
      });
  });
export const fetchUserSubscriptionsBegin = () => ({
  type: FETCH_USER_SUBSCRIPTIONS
});

export const fetchUserSubscriptionsSuccess = (subscriptions, userId) => ({
  type: FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  userId,
  subscriptions
});

export const fetchUserSubscriptionsFailed = () => ({
  type: FETCH_USER_SUBSCRIPTIONS_FAILED
});

export const deleteAssignToCategoryBegin = () => ({
  type: DELETE_ASSIGN_TO_CATEGORY
});

export const deleteAssignToCategorySuccess = (id, userId) => ({
  type: DELETE_ASSIGN_TO_CATEGORY_SUCCESS,
  id,
  userId
});

export const deleteAssignToCategoryFailed = () => ({
  type: DELETE_ASSIGN_TO_CATEGORY_FAILED
});

export const deleteAssignToCategory = (district, id, userId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteAssignToCategoryBegin());

    axios
      .delete(`/${district}/defect_subscription/${id}/`)
      .then(r => {
        dispatch(deleteAssignToCategorySuccess(id, userId));
        dispatch(showSuccess('Subskrypcja została usunięta dla użytkownika'));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(deleteAssignToCategoryFailed());
        reject(err);
      });
  });

export const updateAssignToCategoryBegin = () => ({
  type: UPDATE_ASSIGN_TO_CATEGORY
});

export const updateAssignToCategorySuccess = () => ({
  type: UPDATE_ASSIGN_TO_CATEGORY_SUCCESS
});

export const updateAssignToCategoryFailed = () => ({
  type: UPDATE_ASSIGN_TO_CATEGORY_FAILED
});

export const updateAssignToCategory = (district, id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateAssignToCategoryBegin());

    axios
      .patch(`/${district}/defect_subscription/${id}/`, data)
      .then(r => {
        dispatch(updateAssignToCategorySuccess());
        dispatch(showSuccess('Subskrypcja została usunięta dla użytkownika'));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(updateAssignToCategoryFailed());
        reject(err);
      });
  });
export const setSingleReport = singleReport => ({
  type: SET_SINGLE_REPORT,
  singleReport
});

export const fetchAdminCategoriesBegin = () => ({
  type: FETCH_ADMIN_CATEGORIES_BEGIN
});

export const fetchAdminCategoriesSuccess = categories => ({
  type: FETCH_ADMIN_CATEGORIES_SUCCESS,
  categories
});

export const fetchAdminCategoriesFailed = () => ({
  type: FETCH_ADMIN_CATEGORIES_FAILED
});

export const updateAdminSubcategories = subcategories => ({
  type: UPDATE_ADMIN_SUBCATEGORIES,
  subcategories
});

export const deleteCategoryFromDistrictBegin = () => ({
  type: DELETE_CATEGORY_FROM_DISTRICT_BEGIN
});

export const deleteCategoryFromDistrictSuccess = categoryId => ({
  type: DELETE_CATEGORY_FROM_DISTRICT_SUCCESS,
  categoryId
});

export const deleteCategoryFromDistrictFailed = () => ({
  type: DELETE_CATEGORY_FROM_DISTRICT_FAILED
});

export const deleteCategoryFromDistrict = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteCategoryFromDistrictBegin());

    return axios
      .delete(`${prefix}/defect_category_admin/${id}/`)
      .then(r => {
        dispatch(
          reportApi.util.updateQueryData(
            'fetchAdminCategories',
            { prefix },
            draft => {
              const updatedCache = draft.filter(category => category.id !== id);

              return updatedCache;
            }
          )
        );

        dispatch(deleteCategoryFromDistrictSuccess(id));
        resolve(r);
      })
      .catch(err => {
        dispatch(deleteCategoryFromDistrictFailed());
        reject(err);
      });
  });

export const editCategoriesAssignedToDistrictBegin = () => ({
  type: EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_BEGIN
});

export const editCategoriesAssignedToDistrictSuccess = updatedCategory => ({
  type: EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_SUCCESS,
  updatedCategory
});

export const editCategoriesAssignedToDistrictFailed = () => ({
  type: EDIT_CATEGORY_ASSIGNED_TO_DISTRICT_FAILED
});

const addTypeToCategory = (state, category) => {
  const typeReports = reportSelectors.getReportTypes(state);
  const typeReport = typeReports.find(({ id }) => id === category.type);

  return { ...category, type: typeReport };
};

export const editCategoriesAssignedToDistrict = (prefix, id, requestBody) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(editCategoriesAssignedToDistrictBegin());

    return axios
      .patch(`${prefix}/defect_category_admin/${id}/`, requestBody)
      .then(({ data }) => {
        const state = getState();
        const updatedCategory = addTypeToCategory(state, data);

        dispatch(
          reportApi.util.updateQueryData(
            'fetchAdminCategories',
            { prefix },
            draft => {
              const updatedCache = draft.map(category =>
                category.id === updatedCategory.id ? updatedCategory : category
              );

              return updatedCache;
            }
          )
        );

        dispatch(editCategoriesAssignedToDistrictSuccess(updatedCategory));
        resolve(data);
      })
      .catch(err => {
        dispatch(editCategoriesAssignedToDistrictFailed());
        reject(err);
      });
  });

export const createAdminCategoryBegin = () => ({
  type: CREATE_ADMIN_CATEGORY_BEGIN
});

export const createAdminCategorySuccess = category => ({
  type: CREATE_ADMIN_CATEGORY_SUCCESS,
  category
});

export const createAdminCategoryFailed = () => ({
  type: CREATE_ADMIN_CATEGORY_FAILED
});

export const createAdminCategory = (prefix, requestBody) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(createAdminCategoryBegin());

    return axios
      .post(`${prefix}/defect_category_admin/`, requestBody)
      .then(({ data }) => {
        const state = getState();
        const newCategory = addTypeToCategory(state, data);

        dispatch(
          reportApi.util.updateQueryData(
            'fetchAdminCategories',
            { prefix },
            draft => {
              draft.push(newCategory);

              return draft;
            }
          )
        );

        dispatch(createAdminCategorySuccess(newCategory));
        resolve(newCategory);
      })
      .catch(err => {
        dispatch(createAdminReportCategoryLoadingFinished());
        dispatch(createAdminCategoryFailed());
        reject(err);
      });
  });

export const createAdminSubcategoryBegin = () => ({
  type: CREATE_ADMIN_SUBCATEGORY_BEGIN
});

export const createAdminSubcategorySuccess = subcategory => ({
  type: CREATE_ADMIN_SUBCATEGORY_SUCCESS,
  subcategory
});

export const createAdminSubcategoryFailed = () => ({
  type: CREATE_ADMIN_SUBCATEGORY_FAILED
});

export const createAdminSubcategory = (prefix, requestBody) => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch(createAdminSubcategoryBegin());

    return axios
      .post(`${prefix}/defect_subcategory_admin/`, requestBody)
      .then(({ data }) => {
        dispatch(
          reportApi.util.updateQueryData(
            'fetchAdminSubcategories',
            { prefix },
            draft => {
              draft.push(data);

              return draft;
            }
          )
        );

        dispatch(createAdminSubcategorySuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(createAdminSubcategoryFailed());
        reject(err);
      });
  });

export const editAdminSubcategoryBegin = () => ({
  type: EDIT_ADMIN_SUBCATEGORY_BEGIN
});

export const editAdminSubcategorySuccess = subcategory => ({
  type: EDIT_ADMIN_SUBCATEGORY_SUCCESS,
  subcategory
});

export const editAdminSubcategoryFailed = () => ({
  type: EDIT_ADMIN_SUBCATEGORY_FAILED
});

export const editAdminSubcategory = (district, id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editAdminSubcategoryBegin());

    return axios
      .patch(`${district}/defect_subcategory_admin/${id}`, data)
      .then(r => {
        dispatch(editAdminSubcategorySuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(editAdminSubcategoryFailed());
        reject(err);
      });
  });

//RTQ
export const fetchAllSubcategoriesBegin = () => ({
  type: FETCH_ALL_SUBCATEGORIES_BEGIN
});

export const fetchAllSubcategoriesSuccess = subcategories => ({
  type: FETCH_ALL_SUBCATEGORIES_SUCCESS,
  payload: subcategories
});

export const fetchAllSubcategoriesFailed = () => ({
  type: FETCH_ALL_SUBCATEGORIES_FAILED
});

export const deleteAdminSubcategoryBegin = () => ({
  type: DELETE_ADMIN_SUBCATEGORY_BEGIN
});

export const deleteAdminSubcategorySuccess = deletedSubcategoryId => ({
  type: DELETE_ADMIN_SUBCATEGORY_SUCCESS,
  deletedSubcategoryId
});

export const deleteAdminSubcategoryFailed = () => ({
  type: DELETE_ADMIN_SUBCATEGORY_FAILED
});

export const deleteAdminSubcategory = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteAdminSubcategoryBegin());

    return axios
      .delete(`${prefix}/defect_subcategory_admin/${id}/`)
      .then(r => {
        dispatch(
          reportApi.util.updateQueryData(
            'fetchAdminSubcategories',
            { prefix },
            draft => {
              const updatedRaft = draft.filter(
                ({ id: subcategoryId }) => subcategoryId !== id
              );

              return updatedRaft;
            }
          )
        );

        dispatch(deleteAdminSubcategorySuccess(id));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(deleteAdminSubcategoryFailed());
        reject(err);
      });
  });

export const setFilterCategory = categoryFilter => ({
  type: SET_CATEGORY_FILTER,
  categoryFilter
});

export const setFilterStatus = statusFilter => ({
  type: SET_STATUS_FILTER,
  statusFilter
});

export const zoomToCategory = id => ({
  type: ZOOM_TO_CATEGORY,
  id
});

export const enableFilters = enable => ({
  type: ENABLE_FILTERS,
  payload: enable
});

export const updateReportConfigs = configs => ({
  type: UPDATE_REPORT_CONFIGS,
  payload: configs
});

/**
 *
 * @param {string} prefix
 * @param {number} id
 *
 * Funkcja pobierajaca reportConfig dla danej jednostki o wskazanym ID(RTQ)
 */

//RTQ
export const fetchSingleReportConfigRequest = () => ({
  type: FETCH_REPORT_SINGLE_CONFIG_BEGIN
});

export const fetchSingleReportConfigSuccess = singleConfig => ({
  type: FETCH_REPORT_SINGLE_CONFIG_SUCCESS,
  payload: singleConfig
});

export const fetchSingleReportConfigFailed = () => ({
  type: FETCH_REPORT_SINGLE_CONFIG_FAILED
});

export const createReportConfigsRequest = () => ({
  type: CREATE_REPORT_CONFIG_BEGIN
});

export const createReportConfigsSuccess = addedConfig => ({
  type: CREATE_REPORT_CONFIG_SUCCESS,
  payload: addedConfig
});

export const createReportConfigsFailed = () => ({
  type: CREATE_REPORT_CONFIG_FAILED
});

/**
 *
 * @param {string} prefix
 * @param {object} data
 *
 * Funkcja aktualizująca reportConfig
 */
export const createReportConfig = (prefix, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportConfigsRequest());

    axios
      .post(`${prefix}/district_defect_config/`, data)
      .then(r => {
        dispatch(
          showSuccess('Ustawienia kolorów zgłoszenia zostały utworzone!')
        );
        dispatch(createReportConfigsSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(createReportConfigsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const updateReportConfigsRequest = () => ({
  type: UPDATE_REPORT_CONFIG_BEGIN
});

export const updateReportConfigsSuccess = updatedConfig => ({
  type: UPDATE_REPORT_CONFIG_SUCCESS,
  payload: updatedConfig
});

export const updateReportConfigsFailed = () => ({
  type: UPDATE_REPORT_CONFIG_FAILED
});

export const updateReportConfig = (prefix, id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateReportConfigsRequest());

    axios
      .patch(`${prefix}/district_defect_config/${id}/`, data)
      .then(r => {
        dispatch(updateReportConfigsSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(updateReportConfigsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteReportConfigRequest = () => ({
  type: DELETE_REPORT_CONFIG_BEGIN
});

export const deleteReportConfigSuccess = configId => ({
  type: DELETE_REPORT_CONFIG_SUCCESS,
  payload: configId
});

export const deleteReportConfigFailed = () => ({
  type: DELETE_REPORT_CONFIG_FAILED
});

/**
 *
 * @param {string} prefix
 * @param {number} id
 * @param {object} data
 *
 * Funkcja usuwająca stworzony wczensiej defect config
 */
export const deleteReportConfig = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteReportConfigRequest());

    axios
      .delete(`${prefix}/district_defect_config/${id}/`)
      .then(() => {
        dispatch(showSuccess('Konfiguracja została usunięta!'));
        dispatch(deleteReportConfigSuccess(id));
        resolve();
      })
      .catch(err => {
        dispatch(deleteReportConfigFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

/**
 *
 * @param {number} configId
 *
 * Funckja pobierająca listę kateogrii dla konifguracji o danym ID(RTQ)
 *
 */

//RTQ
export const fetchReportCategoryConfigRequest = () => ({
  type: FETCH_REPORT_CATEGORY_CONFIG_BEIGN
});

export const fetchReportCategoryConfigSuccess = categoryConfig => ({
  type: FETCH_REPORT_CATEGORY_CONFIG_SUCCESS,
  payload: categoryConfig
});

export const fetchReportCategoryConfigFailed = () => ({
  type: FETCH_REPORT_CATEGORY_CONFIG_FAILED
});

export const fetchReportStatusConfigRequest = () => ({
  type: FETCH_REPORT_STATUS_CONFIG_BEIGN
});

export const fetchReportStatusConfigSuccess = statusConfig => ({
  type: FETCH_REPORT_STATUS_CONFIG_SUCCESS,
  payload: statusConfig
});

export const fetchReportStatusConfigFailed = () => ({
  type: FETCH_REPORT_STATUS_CONFIG_FAILED
});

/**
 *
 * @param {number} configId
 *
 * Funckja pobierająca listę statusów dla konifguracji o danym ID
 *
 */
export const fetchReportStatusConfig = (prefix, configId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchReportStatusConfigRequest());

    axios
      .get(`${prefix}/defect_all_status_configs/${configId}/`)
      .then(r => {
        dispatch(fetchReportStatusConfigSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchReportStatusConfigFailed());
        reject(err);
      });
  });

export const createReportCategoryColorRequest = () => ({
  type: CRAETE_REPORT_CATEGORY_COLOR_BEGIN
});

export const createReportCategoryColorSuccess = createdCategoryColor => ({
  type: CRAETE_REPORT_CATEGORY_COLOR_SUCCESS,
  payload: createdCategoryColor
});

export const createReportCategoryColorFailed = () => ({
  type: CRAETE_REPORT_CATEGORY_COLOR_FAILED
});

/**
 *
 * @param {number} configId
 * @param {object} data
 *
 * Funkcja tworzy kolor do danej konfiguracji
 */
export const createReportCategoryColor = (prefix, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportCategoryColorRequest());

    axios
      .post(`${prefix}/defect_category_config/`, data)
      .then(r => {
        dispatch(createReportCategoryColorSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(createReportCategoryColorFailed());
        reject(err);
      });
  });

export const createReportStatusColorRequest = () => ({
  type: CRAETE_REPORT_STATUS_COLOR_BEGIN
});

export const createReportStatusColorSuccess = createdCategoryColor => ({
  type: CREATE_REPORT_STATUS_COLOR_SUCCESS,
  payload: createdCategoryColor
});

export const createReportStatusColorFailed = () => ({
  type: CRAETE_REPORT_STATUS_COLOR_FAILED
});

/**
 *
 * @param {number} configId
 * @param {object} data
 *
 * Funkcja tworzy kolor do danej konfiguracji
 */
export const createReportStatusColor = (prefix, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createReportStatusColorRequest());

    axios
      .post(`${prefix}/defect_status_config/`, data)
      .then(r => {
        dispatch(createReportStatusColorSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(createReportStatusColorFailed());
        reject(err);
      });
  });

export const updateReportCategoryColorRequest = () => ({
  type: UPDATE_REPORT_CATEGORY_COLOR_BEGIN
});

export const updateReportCategoryColorSuccess = updatedCategoryColor => ({
  type: UPDATE_REPORT_CATEGORY_COLOR_SUCCESS,
  payload: updatedCategoryColor
});

export const updateReportCategoryColorFailed = () => ({
  type: UPDATE_REPORT_CATEGORY_COLOR_FAILED
});

/**
 * @param {number} id
 * @param {number} configId
 * @param {object} data
 *
 * Funkcja aktualizuje kolor kategorii
 */
export const updateReportCategoryColor = (prefix, configId, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateReportCategoryColorRequest());

    axios
      .patch(`${prefix}/defect_category_config/${configId}/`, data)
      .then(r => {
        dispatch(updateReportCategoryColorSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(updateReportCategoryColorFailed());
        reject(err);
      });
  });

export const updateReportStatusColorRequest = () => ({
  type: UPDATE_REPORT_STATUS_COLOR_BEGIN
});

export const updateReportStatusColorSuccess = updatedStatusColor => ({
  type: UPDATE_REPORT_STATUS_COLOR_SUCCESS,
  payload: updatedStatusColor
});

export const updateReportStatusColorFailed = () => ({
  type: UPDATE_REPORT_STATUS_COLOR_FAILED
});

/**
 *
 * @param {number} configId
 * @param {object} data
 *
 * Funkcja tworzy kolor do danej konfiguracji
 */
export const updateReportStatusColor = (prefix, configId, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateReportStatusColorRequest());

    delete data.config;
    delete data.status;

    axios
      .patch(`${prefix}/defect_status_config/${configId}/`, data)
      .then(r => {
        dispatch(updateReportStatusColorSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(updateReportStatusColorFailed());
        reject(err);
      });
  });

export const clearReportColors = () => ({
  type: CLEAR_REPORT_COLORS
});

/**
 *
 * Pobiera typy zgłoszenia (usterka, propzycja itp.)
 *
 * Dane zapisywane są pod zmienną reportTypes(RTQ)
 */

//RTQ
export const getTypeReportsRequest = () => ({
  type: GET_TYPE_REPORTS_REQUEST
});

export const getTypeReportsSuccess = types => ({
  type: GET_TYPE_REPORTS_SUCCESS,
  payload: types
});

export const getTypeReportsFailed = () => ({
  type: GET_TYPE_REPORTS_FAILED
});

export const getDefaultStatusTypeReportRequest = () => ({
  type: GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST
});

export const getDefaultStatusTypeReportSuccess = data => ({
  type: GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  payload: data
});

export const getDefaultStatusTypeReportFailed = () => ({
  type: GET_DEFAULT_STATUS_TYPE_REPORT_FAILED
});

export const updateDefaultStatusTypeReportRequest = () => ({
  type: UPDATE_DEFAULT_STATUS_TYPE_REPORT_REQUEST
});

export const updateDefaultStatusTypeReportSuccess = data => ({
  type: UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  payload: data
});

export const updateDefaultStatusTypeReportFailed = () => ({
  type: UPDATE_DEFAULT_STATUS_TYPE_REPORT_FAILED
});

/**
 *
 * Aktualizuje domyślny status dla danego typu zgłoszenia
 *
 * @param {id: number, type: number} config
 * id - id domyślnego statusu (model BE - DefaultStatusTypeReport)
 * type - id statusu (model BE - DefectStatus)
 *
 */
export const updateDefaultStatusTypeReport = config => async dispatch => {
  dispatch(updateDefaultStatusTypeReportRequest());

  try {
    const { data } = await axios.patch(
      `default_status_type_report/${config.id}/`,
      config
    );
    dispatch(updateDefaultStatusTypeReportSuccess(data));
  } catch (e) {
    dispatch(updateDefaultStatusTypeReportFailed());
  }
};

/**
 * Określa jakie typy zgłoszeń ma wyświetlać na mapie
 * {null} - wyświetla wszystkie
 * {number} - wyświetla dany typ danych
 *
 * Zapisane w Reduxie pod nazwą: showTypeReport
 *
 * @param {null|number} data
 */
export const setShowTypeReport = data => ({
  type: SHOW_TYPE_REPORT,
  payload: data
});

export const createTypeReportRequest = () => ({
  type: CREATE_TYPE_REPORT_REQUEST
});

export const createTypeReportSuccess = data => ({
  type: CREATE_TYPE_REPORT_SUCCESS,
  payload: data
});

export const createTypeReportFailed = () => ({
  type: CREATE_TYPE_REPORT_FAILED
});

/**
 *
 * Tworzy nowy typ zgloszenia
 *
 * Zmienne w reduxie:
 * creatingTypeReport (bool) - czas trwania dodawania
 *
 * Po uzyskaniu stautsu 201 dodajemy nowy obiekt do zmiennej typeReports w reduxie
 *
 * @param {string} name - nazwa typu zgłoszenia
 */
export const createTypeReport = name => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch(createTypeReportRequest());

    try {
      const { data } = await axios.post(`type_report/`, {
        name
      });
      dispatch(createTypeReportSuccess(data));
      dispatch(showSuccess('Utworzono nowy rodzaj zgłoszeń'));
      resolve();
    } catch (e) {
      dispatch(createTypeReportFailed());
      dispatch(showError(parseResponseError(e)));
      reject(e);
    }
  });

export const deleteTypeReportRequest = () => ({
  type: DELETE_TYPE_REPORT_REQUEST
});

export const deleteTypeReportSuccess = id => ({
  type: DELETE_TYPE_REPORT_SUCCESS,
  payload: id
});

export const deleteTypeReportFailed = () => ({
  type: DELETE_TYPE_REPORT_FAILED
});

/**
 *
 * Usuwa  typ zgloszenia
 *
 * Zmienne w reduxie:
 * deleteingTypeReport (bool) - czas trwania usuwania
 *
 * Po uzyskaniu stautsu 204 usuwamy obiekt z zmiennej typeReports w reduxie
 *
 * @param {numer} id - nazwa typu zgłoszenia
 */
export const deleteTypeReport = id => async dispatch => {
  dispatch(deleteTypeReportRequest());

  try {
    await axios.delete(`type_report/${id}/`);
    dispatch(deleteTypeReportSuccess(id));
    dispatch(showSuccess('Rodzaj zgłoszenia został usunięty.'));
  } catch (e) {
    dispatch(deleteTypeReportFailed());
    dispatch(showError(parseResponseError(e)));
  }
};

export const updateTypeReportRequest = () => ({
  type: UPDATE_TYPE_REPORT_REQUEST
});

export const updateTypeReportSuccess = data => ({
  type: UPDATE_TYPE_REPORT_SUCCESS,
  payload: data
});

export const updateTypeReportFailed = () => ({
  type: UPDATE_TYPE_REPORT_FAILED
});

export const fetchDefectConfigurationSuccess = data => ({
  type: FETCH_DEFECT_CONFIGURATION_SUCCESS,
  payload: data
});

export const updateDefectConfigurationStarted = () => ({
  type: UPDATE_DEFECT_CONFIGURATION_STARTED
});

export const updateDefectConfigurationSuccess = data => ({
  type: UPDATE_DEFECT_CONFIGURATION_SUCCESS,
  payload: data
});

export const updateDefectConfigurationFailed = () => ({
  type: UPDATE_DEFECT_CONFIGURATION_FAILED
});

export const updateDefectConfiguration = ({
  district,
  id,
  formData,
  successMessage
}) => async dispatch => {
  dispatch(updateDefectConfigurationStarted());
  try {
    const response = await axios.patch(
      `${district}/defect_configuration/${id}/`,
      formData
    );
    dispatch(updateDefectConfigurationSuccess(response.data));
    dispatch(showSuccess(successMessage));
  } catch (error) {
    dispatch(updateDefectConfigurationFailed());
    dispatch(showError(error));
  }
};

export const getCategoryAttributesStarted = () => ({
  type: GET_CATEGORY_ATTRIBUTES_STARTED
});

export const getCategoryAttributesSuccess = data => ({
  type: GET_CATEGORY_ATTRIBUTES_SUCCESS,
  payload: data
});

export const getCategoryAttributesFailed = () => ({
  type: GET_CATEGORY_ATTRIBUTES_FAILED
});

export const getCategoryAttributes = (prefix, categoryId) => async dispatch => {
  dispatch(getCategoryAttributesStarted());

  try {
    const { data } = await axios.get(
      `${prefix}/defect_category/${categoryId}/attribute/`
    );
    dispatch(getCategoryAttributesSuccess(data));
  } catch {
    dispatch(getCategoryAttributesFailed());
  }
};

export const addCategoryAttributesStarted = () => ({
  type: ADD_CATEGORY_ATTRIBUTE_STARTED
});

export const addCategoryAttributesSuccess = data => ({
  type: ADD_CATEGORY_ATTRIBUTE_SUCCESS,
  payload: data
});

export const addCategoryAttributesFailed = () => ({
  type: ADD_CATEGORY_ATTRIBUTE_FAILED
});

export const addCategoryAttributes = ({
  prefix,
  categoryId,
  name,
  required,
  index
}) => async dispatch => {
  dispatch(addCategoryAttributesStarted());

  try {
    const { data } = await axios.post(
      `${prefix}/defect_category/${categoryId}/attribute/`,
      {
        name,
        required,
        category: categoryId,
        index
      }
    );
    dispatch(addCategoryAttributesSuccess(data));
    dispatch(getCategoryAttributes(prefix, categoryId));
    return data;
  } catch (error) {
    dispatch(addCategoryAttributesFailed(error));
  }
};

export const deleteCategoryAttributeFailed = () => ({
  type: DELETE_CATEGORY_ATTRIBUTES_FAILED
});

export const deleteCategoryAttributeStarted = () => ({
  type: DELETE_CATEGORY_ATTRIBUTES_STARTED
});

export const deleteCategoryAttributeSuccess = () => ({
  type: DELETE_CATEGORY_ATTRIBUTES_SUCCESS
});

export const deleteCategoryAttribute = (
  attributeId,
  prefix,
  categoryId
) => async dispatch => {
  dispatch(deleteCategoryAttributeStarted());
  try {
    await axios.delete(
      `${prefix}/defect_category/${categoryId}/attribute/${attributeId}/`
    );
    dispatch(deleteCategoryAttributeSuccess());
    dispatch(getCategoryAttributes(prefix, categoryId));
  } catch {
    dispatch(deleteCategoryAttributeFailed());
  }
};

export const clearCategoryAttributes = () => ({
  type: CLEAR_CATEGORY_ATTRIBUTES
});

export const updateCategoryAttributeStarted = () => ({
  type: UPDATE_CATEGORY_ATTRIBUTES_STARTED
});

export const updateCategoryAttributeSuccess = () => ({
  type: UPDATE_CATEGORY_ATTRIBUTES_SUCCESS
});

export const updateCategoryAttributeFailed = () => ({
  type: UPDATE_CATEGORY_ATTRIBUTES_FAILED
});

export const updateCategoryAttribute = (
  prefix,
  attributeId,
  categoryId,
  isSwapIndexes = false,
  data
) => async dispatch => {
  dispatch(updateCategoryAttributeStarted());
  try {
    await axios.patch(
      `${prefix}/defect_category/${categoryId}/attribute/${attributeId}/`,
      data
    );
    dispatch(updateCategoryAttributeSuccess());
    !isSwapIndexes && dispatch(getCategoryAttributes(prefix, categoryId));
  } catch (error) {
    dispatch(updateCategoryAttributeFailed(error));
  }
};

/**
 *
 * Aktualizuje typ zgłoszenia
 *
 * Zmienne w reduxie:
 * updateingTypeReport (bool) - czas trwania aktualizacji
 *
 * Po uzyskaniu stautsu 200 aktualizujemy obiekt w typeReports w reduxie
 *
 * @param {numer} id - nazwa typu zgłoszenia
 */
export const updateTypeReport = (id, config) => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch(updateTypeReportRequest());

    try {
      const { data } = await axios.patch(`type_report/${id}/`, config);
      dispatch(updateTypeReportSuccess(data));
      dispatch(showSuccess('Rodzaj zgłoszenia został zaktualizowany.'));
      resolve();
    } catch (e) {
      dispatch(updateTypeReportFailed());
      dispatch(showError(parseResponseError(e)));
      reject(e);
    }
  });
