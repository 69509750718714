import axios from 'axios';
import { fromJS } from 'immutable';
import { reject } from 'lodash';

import { editDataApi } from '../api/editData';

import { EEditDataTags } from '@/types/api-types/edit-data';

import {
  FETCH_LAYER_FORMS_BEGIN,
  FETCH_LAYER_FORMS_SUCCESS,
  FETCH_LAYER_FORMS_FAILED,
  EDIT_LAYER_FORM_SUCCESS,
  FETCH_ADMIN_LAYER_OBJECTS_BEGIN,
  FETCH_ADMIN_LAYER_OBJECTS_SUCCESS,
  FETCH_ADMIN_LAYER_OBJECTS_FAILED,
  GET_ADMIN_LAYER_OBJECT_BEGIN,
  GET_ADMIN_LAYER_OBJECT_SUCCESS,
  GET_ADMIN_LAYER_OBJECT_FAILED,
  UPDATE_ADMIN_LAYER_OBJECT_BEGIN,
  UPDATE_ADMIN_LAYER_OBJECT_SUCCESS,
  UPDATE_ADMIN_LAYER_OBJECT_FAILED,
  OPTIONS_ADMIN_LAYER_OBJECTS_BEGIN,
  OPTIONS_ADMIN_LAYER_OBJECTS_FAILED,
  OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS,
  GET_USER_FORMS_SUCCESS,
  GET_USER_FORM_BEGIN,
  GET_USER_FORM_SUCCESS,
  GET_USER_FORM_FAILED,
  FETCH_SUBSCRIBER_LAYER_OBJECTS_BEGIN,
  FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  FETCH_SUBSCRIBER_LAYER_OBJECTS_FAILED,
  OPTIONS_SUBSCRIBER_LAYER_OBJECTS_BEGIN,
  OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  OPTIONS_SUBSCRIBER_LAYER_OBJECTS_FAILED,
  GET_SUBSCRIBER_LAYER_OBJECT_BEGIN,
  GET_SUBSCRIBER_LAYER_OBJECT_SUCCESS,
  GET_SUBSCRIBER_LAYER_OBJECT_FAILED,
  UPDATE_SUBSCRIBER_LAYER_OBJECT_BEGIN,
  UPDATE_SUBSCRIBER_LAYER_OBJECT_SUCCESS,
  UPDATE_SUBSCRIBER_LAYER_OBJECT_FAILED,
  DELETE_OBJECT_BEGIN,
  DELETE_OBJECT_SUCCESS,
  DELETE_OBJECT_FAILED,
  DELETE_SUBSCRIBER_LAYER_OBJECT_BEGIN,
  DELETE_SUBSCRIBER_LAYER_OBJECT_SUCCESS,
  DELETE_SUBSCRIBER_LAYER_OBJECT_FAILED,
  DELETE_ADMIN_LAYER_OBJECT_BEGIN,
  DELETE_ADMIN_LAYER_OBJECT_SUCCESS,
  DELETE_ADMIN_LAYER_OBJECT_FAILED,
  GET_OBJECT_ATTACHMENTS_BEGIN,
  GET_OBJECT_ATTACHMENTS_FAILED,
  GET_OBJECT_ATTACHMENTS_SUCCESS,
  ADD_OBJECT_ATTACHMENTS_BEGIN,
  ADD_OBJECT_ATTACHMENTS_SUCCESS,
  ADD_OBJECT_ATTACHMENTS_FAILED,
  DELETE_OBJECT_ATTACHMENT_BEGIN,
  DELETE_OBJECT_ATTACHMENT_FAILED,
  DELETE_OBJECT_ATTACHMENT_SUCCESS,
  UPDATE_OBJECT_ATTACHMENTS_BEGIN,
  UPDATE_OBJECT_ATTACHMENTS_SUCCESS
} from '../constants/editDataTypes';

import getFormDictionaries from '../../services/getFormDictionaries';

import handleError from '../../utils/handleError';
import { DATA_FORMAT_WITH_TIME } from '../../utils/constants';

export const createLayerForm = (district, data) => dispatch =>
  new Promise((resolve, reject) => {
    axios
      .post(`${district}/admin_layer_forms/`, data)
      .then(response => {
        if (!response) return;

        dispatch(
          editDataApi.util.invalidateTags([{ type: EEditDataTags.LAYER_FORMS }])
        );

        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        reject(err);
      });
  });

export const fetchLayerFormsBegin = () => ({
  type: FETCH_LAYER_FORMS_BEGIN
});

export const fetchLayerFormsSuccess = payload => ({
  type: FETCH_LAYER_FORMS_SUCCESS,
  payload
});

export const fetchLayerFormsFailed = () => ({
  type: FETCH_LAYER_FORMS_FAILED
});

export const editLayerFormSuccess = (district, payload) => ({
  type: EDIT_LAYER_FORM_SUCCESS,
  district,
  payload
});

export const fetchLayerObjectsBegin = () => ({
  type: FETCH_ADMIN_LAYER_OBJECTS_BEGIN
});

export const fetchLayerObjectsSuccess = payload => ({
  type: FETCH_ADMIN_LAYER_OBJECTS_SUCCESS,
  payload
});

export const fetchLayerObjectsFailed = () => ({
  type: FETCH_ADMIN_LAYER_OBJECTS_FAILED
});

export const fetchLayerObjects = ({
  district,
  page,
  resultsPerPage,
  dataFilter
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchLayerObjectsBegin());

    const prepareCreated = () => {
      const start = dataFilter?.createdStart;
      const end = dataFilter?.createdEnd;

      if (start && end) {
        return `${start.format(DATA_FORMAT_WITH_TIME)},${end.format(
          DATA_FORMAT_WITH_TIME
        )}`;
      } else if (start && !end) {
        return `${start.format(DATA_FORMAT_WITH_TIME)}`;
      } else if (!start && end) {
        return `,${end.format(DATA_FORMAT_WITH_TIME)}`;
      } else {
        return null;
      }
    };

    axios
      .get(`${district}/admin_layer_objects/`, {
        params: {
          page: page,
          page_size: resultsPerPage,
          status: dataFilter?.status,
          search: dataFilter?.search,
          operation: dataFilter?.operation,
          created: prepareCreated()
        }
      })

      .then(response => {
        if (!response) return;
        dispatch(
          fetchLayerObjectsSuccess({
            district,
            layerObjects: response.data
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(fetchLayerObjectsFailed());
        reject(err);
      });
  });

export const getLayerObjectBegin = () => ({
  type: GET_ADMIN_LAYER_OBJECT_BEGIN
});

export const getLayerObjectSuccess = payload => ({
  type: GET_ADMIN_LAYER_OBJECT_SUCCESS,
  payload
});

export const getLayerObjectFailed = () => ({
  type: GET_ADMIN_LAYER_OBJECT_FAILED
});

export const getLayerObject = (district, objectId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getLayerObjectBegin());

    axios
      .get(`${district}/admin_layer_objects/${objectId}/`)
      .then(response => {
        if (!response) return;
        dispatch(
          getLayerObjectSuccess({
            district,
            layerObjects: response.data
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(getLayerObjectFailed());
        reject(err);
      });
  });

export const updateLayerObjectBegin = () => ({
  type: UPDATE_ADMIN_LAYER_OBJECT_BEGIN
});

export const updateLayerObjectSuccess = () => ({
  type: UPDATE_ADMIN_LAYER_OBJECT_SUCCESS
});

export const updateLayerObjectFailed = () => ({
  type: UPDATE_ADMIN_LAYER_OBJECT_FAILED
});

export const updateLayerObject = (district, objectId, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateLayerObjectBegin());
    axios
      .patch(`${district}/admin_layer_objects/${objectId}/`, data)
      .then(response => {
        dispatch(updateLayerObjectSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(updateLayerObjectFailed());
        reject(err);
      });
  });
export const deleteLayerObjectBegin = () => ({
  type: DELETE_ADMIN_LAYER_OBJECT_BEGIN
});

export const deleteLayerObjectSuccess = () => ({
  type: DELETE_ADMIN_LAYER_OBJECT_SUCCESS
});

export const deleteLayerObjectFailed = () => ({
  type: DELETE_ADMIN_LAYER_OBJECT_FAILED
});

export const deleteLayerObject = (district, objectId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteLayerObjectBegin());
    axios
      .delete(`${district}/admin_layer_objects/${objectId}/`)
      .then(({ data }) => {
        dispatch(deleteLayerObjectSuccess());
        resolve(data);
      })
      .catch(err => {
        dispatch(deleteLayerObjectFailed());
        reject(err);
      });
  });

export const optionsAdminLayerObjectsBegin = () => ({
  type: OPTIONS_ADMIN_LAYER_OBJECTS_BEGIN
});

export const optionsAdminLayerObjectsSuccess = options => ({
  type: OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS,
  options
});

export const optionsAdminLayerObjectsFailed = () => ({
  type: OPTIONS_ADMIN_LAYER_OBJECTS_FAILED
});

export const optionsAdminLayerObjects = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(optionsAdminLayerObjectsBegin());
    axios
      .options(`${district}/admin_layer_objects/`)
      .then(response => {
        const options = {};
        options.operations = response.data.actions.GET.operation.choices;
        options.status = response.data.actions.GET.status.choices;
        dispatch(optionsAdminLayerObjectsSuccess(options));
        resolve(options);
      })
      .catch(error => {
        dispatch(optionsAdminLayerObjectsFailed());
        reject(error);
      });
  });

export const getUserFormsSuccess = (district, userForms) => ({
  type: GET_USER_FORMS_SUCCESS,
  district,
  userForms
});

export const getUserFormBegin = () => ({
  type: GET_USER_FORM_BEGIN
});

export const getUserFormSuccess = (district, userForm) => ({
  type: GET_USER_FORM_SUCCESS,
  district,
  userForm
});

export const getUserFormFailed = () => ({
  type: GET_USER_FORM_FAILED
});

export const getUserForm = (district, formId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getUserFormBegin());

    axios
      .get(`${district}/forms/${formId}/`)
      .then(async ({ data }) => {
        const { dictionaries, dictionariesMap } = await getFormDictionaries({
          attributes: data.attrs,
          district,
          formId
        });

        data.dictionaries = fromJS({
          values: dictionaries,
          mappedValues: dictionariesMap
        });

        dispatch(getUserFormSuccess(district, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getUserFormFailed());
        reject(err);
      });
  });

export const fetchSubscriberLayerObjectsBegin = () => ({
  type: FETCH_SUBSCRIBER_LAYER_OBJECTS_BEGIN
});

export const fetchSubscriberLayerObjectsSuccess = payload => ({
  type: FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  payload
});

export const fetchSubscriberLayerObjectsFailed = () => ({
  type: FETCH_SUBSCRIBER_LAYER_OBJECTS_FAILED
});

export const fetchSubscriberLayerObjects = ({
  district,
  page,
  resultsPerPage,
  dataFilter
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSubscriberLayerObjectsBegin());

    const prepareCreated = () => {
      const start = dataFilter?.createdStart;
      const end = dataFilter?.createdEnd;

      if (start && end) {
        return `${start.format(DATA_FORMAT_WITH_TIME)},${end.format(
          DATA_FORMAT_WITH_TIME
        )}`;
      } else if (start && !end) {
        return `${start.format(DATA_FORMAT_WITH_TIME)}`;
      } else if (!start && end) {
        return `,${end.format(DATA_FORMAT_WITH_TIME)}`;
      } else {
        return null;
      }
    };

    axios
      .get(`${district}/subscriber_layer_objects/`, {
        params: {
          page,
          page_size: resultsPerPage,
          status: dataFilter?.status,
          search: dataFilter?.search,
          operation: dataFilter?.operation,
          created: prepareCreated()
        }
      })
      .then(response => {
        if (!response) return;
        dispatch(
          fetchSubscriberLayerObjectsSuccess({
            district,
            layerSubscriptionsObjects: response.data
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(fetchSubscriberLayerObjectsFailed());
        reject(err);
      });
  });

export const optionsSubscriberLayerObjectsBegin = () => ({
  type: OPTIONS_SUBSCRIBER_LAYER_OBJECTS_BEGIN
});

export const optionsSubscriberLayerObjectsSuccess = options => ({
  type: OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  options
});

export const optionsSubscriberLayerObjectsFailed = () => ({
  type: OPTIONS_SUBSCRIBER_LAYER_OBJECTS_FAILED
});

export const optionsSubscriberLayerObjects = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(optionsSubscriberLayerObjectsBegin());
    axios
      .options(`${district}/subscriber_layer_objects/`)
      .then(response => {
        const options = {};
        options.operations = response.data.actions.GET.operation.choices;
        options.status = response.data.actions.GET.status.choices;
        dispatch(optionsSubscriberLayerObjectsSuccess(options));
        resolve(options);
      })
      .catch(error => {
        dispatch(optionsSubscriberLayerObjectsFailed());
        reject(error);
      });
  });

export const getSubscriberLayerObjectBegin = () => ({
  type: GET_SUBSCRIBER_LAYER_OBJECT_BEGIN
});

export const getSubscriberLayerObjectSuccess = payload => ({
  type: GET_SUBSCRIBER_LAYER_OBJECT_SUCCESS,
  payload
});

export const getSubscriberLayerObjectFailed = () => ({
  type: GET_SUBSCRIBER_LAYER_OBJECT_FAILED
});

export const getSubscriberLayerObjectgetLayerObject = (
  district,
  objectId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getSubscriberLayerObjectBegin());

    axios
      .get(`${district}/subscriber_layer_objects/${objectId}/`)
      .then(response => {
        if (!response) return;
        dispatch(
          getSubscriberLayerObjectSuccess({
            district,
            layerObjects: response.data
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(getSubscriberLayerObjectFailed());
        reject(err);
      });
  });

export const updateSubscriberLayerObjectBegin = () => ({
  type: UPDATE_SUBSCRIBER_LAYER_OBJECT_BEGIN
});

export const updateSubscriberLayerObjectSuccess = () => ({
  type: UPDATE_SUBSCRIBER_LAYER_OBJECT_SUCCESS
});

export const updateSubscriberLayerObjectFailed = () => ({
  type: UPDATE_SUBSCRIBER_LAYER_OBJECT_FAILED
});

export const updateSubscriberLayerObject = (
  district,
  objectId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateSubscriberLayerObjectBegin());
    axios
      .patch(`${district}/subscriber_layer_objects/${objectId}/`, data)
      .then(response => {
        dispatch(updateSubscriberLayerObjectSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(updateSubscriberLayerObjectFailed());
        reject(err);
      });
  });

export const deleteSubscriberLayerObjectBegin = () => ({
  type: DELETE_SUBSCRIBER_LAYER_OBJECT_BEGIN
});

export const deleteSubscriberLayerObjectSuccess = () => ({
  type: DELETE_SUBSCRIBER_LAYER_OBJECT_SUCCESS
});

export const deleteSubscriberLayerObjectFailed = () => ({
  type: DELETE_SUBSCRIBER_LAYER_OBJECT_FAILED
});

export const deleteSubscriberLayerObject = (district, objectId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteSubscriberLayerObjectBegin());
    axios
      .delete(`${district}/subscriber_layer_objects/${objectId}/`)
      .then(({ data }) => {
        dispatch(deleteSubscriberLayerObjectSuccess());
        resolve(data);
      })
      .catch(err => {
        dispatch(deleteSubscriberLayerObjectFailed());
        reject(err);
      });
  });

export const deleteObjectBegin = () => ({
  type: DELETE_OBJECT_BEGIN
});

export const deleteObjectSuccess = () => ({
  type: DELETE_OBJECT_SUCCESS
});

export const deleteObjectFailed = () => ({
  type: DELETE_OBJECT_FAILED
});

export const deleteObject = ({ prefix, formId, objectId }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteObjectBegin());

    axios
      .delete(`${prefix}/forms/${formId}/layer/${objectId}/`)
      .then(({ data }) => {
        dispatch(deleteObjectSuccess());
        resolve(data);
      })
      .catch(err => {
        dispatch(deleteObjectFailed());
        reject(err);
      });
  });

export const getObjectAttachmentsBegin = () => ({
  type: GET_OBJECT_ATTACHMENTS_BEGIN
});

export const getObjectAttachmentsSuccess = ({ payload }) => ({
  type: GET_OBJECT_ATTACHMENTS_SUCCESS,
  payload
});

export const getObjectAttachmentsFailed = () => ({
  type: GET_OBJECT_ATTACHMENTS_FAILED
});

export const getObjectAttachments = (
  objectId,
  district,
  formLayerId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getSubscriberLayerObjectBegin());

    axios
      .get(
        `${district}/user_layer_objects/?page=1&page_size=5&obj_id=${objectId}&status=ACCEPTED&layer=${formLayerId}`
      )
      .then(({ data }) => {
        dispatch(
          getObjectAttachmentsSuccess({
            district,
            payload: data?.results
              ?.map(({ attachments }) => attachments)
              .flat(1)
          })
        );

        resolve(data);
      })
      .catch(err => {
        handleError(err);
        dispatch(getObjectAttachmentsFailed());
        reject(err);
      });
  });

export const addObjectAttachmentsBegin = () => ({
  type: ADD_OBJECT_ATTACHMENTS_BEGIN
});

export const addObjectAttachmentsSuccess = payload => ({
  type: ADD_OBJECT_ATTACHMENTS_SUCCESS,
  payload
});

export const addObjectAttachmentsFailed = error => ({
  type: ADD_OBJECT_ATTACHMENTS_FAILED,
  error
});

export const addObjectAttachments = (
  id,
  newObjectData,
  district
) => async dispatch => {
  dispatch(addObjectAttachmentsBegin());

  const formData = new FormData();

  Object.keys(newObjectData).forEach(key => {
    if (newObjectData[key] !== null && newObjectData[key] !== undefined) {
      formData.append(key, newObjectData[key]);
    }
  });
  try {
    const response = await axios.post(
      `${district}/user_layer_objects/${id}/attachments/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    dispatch(addObjectAttachmentsSuccess(response.data));
    return response.data;
  } catch (err) {
    dispatch(addObjectAttachmentsFailed(err));
  }
};

export const updateObjectAttachmentsBegin = () => ({
  type: UPDATE_OBJECT_ATTACHMENTS_BEGIN
});

export const updateObjectAttachmentsSuccess = payload => ({
  type: UPDATE_OBJECT_ATTACHMENTS_SUCCESS,
  payload
});

export const updateObjectAttachments = ({
  layerObjectId: id,
  updatedAttachment: newObjectData,
  district
}) => async dispatch => {
  dispatch(updateObjectAttachmentsBegin());
  const formData = new FormData();

  Object.keys(newObjectData).forEach(key => {
    if ((key === 'file' || key === 'filename') && newObjectData[key] === null) {
      formData.append(key, undefined);
    }
    if (newObjectData[key] !== null && newObjectData[key] !== undefined) {
      formData.append(key, newObjectData[key]);
    }
  });
  axios
    .post(`${district}/user_layer_objects/${id}/attachments/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => updateObjectAttachmentsSuccess(response))
    .catch(error => reject(error));
};

export const deleteObjectAttachmentBegin = () => ({
  type: DELETE_OBJECT_ATTACHMENT_BEGIN
});

export const deleteObjectAttachmentSuccess = () => ({
  type: DELETE_OBJECT_ATTACHMENT_SUCCESS
});

export const deleteObjectAttachmentFailed = () => ({
  type: DELETE_OBJECT_ATTACHMENT_FAILED
});

export const deleteObjectAttachment = (
  district,
  objectId,
  id,
  newObjectData
) => dispatch =>
  new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.keys(newObjectData).forEach(key => {
      if (newObjectData[key] !== null && newObjectData[key] !== undefined) {
        formData.append(key, newObjectData[key]);
      }
    });
    dispatch(deleteObjectAttachmentBegin());

    axios
      .post(
        `${district}/user_layer_objects/${objectId}/attachments/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response => {
        if (!response) return;
        dispatch(deleteObjectAttachmentSuccess());
        resolve();
      })
      .catch(err => {
        handleError(err);
        dispatch(deleteObjectAttachmentFailed());
        reject(err);
      });
  });
