export const enum EDistrictTags {
  DISTRICT_ROLES = 'districtRoles',
  MAP_PORTAL_APPEARANCE = 'mapPortalAppearance',
  APPEARANCE = 'appearance',
  DISTRICTS_SETTINGS = 'districtsSettings'
}

export type TDistrictRolesParams = {
  district: string;
};

export type TMapPortalAppearanceQuery = {
  prefix: string;
  appearance: number;
};

export type TAppearanceParams = {
  district: string;
  page: number;
  pageSize: number;
  id?: number;
};
