import { createApi } from '@reduxjs/toolkit/query/react';

import handleApiError from '@/utils/handleApiError';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  fetchDistrictRolesWithSuperAdminBegin,
  fetchDistrictRolesWithSuperAdminFailed,
  fetchDistrictRolesWithSuperAdminSuccess
} from '@/store/actions/districtActions';
import {
  addUserToList,
  fetchUserByIdBegin,
  fetchUserByIdFailed,
  fetchUserByIdSuccess
} from '@/store/actions/userActions';

import {
  EUserTags,
  TAdminUserRolesParams,
  TGlobalAuthUserParams,
  TUserByIdParams
} from '@/types/api-types/users';
import { TUserData } from '@/types/user-data';
import { AdminUserRolesResponse } from '@/types/responses/users';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EUserTags.ADMIN_USER_ROLES,
    EUserTags.USER_BY_ID,
    EUserTags.GLOBAL_USERS_DATA
  ],
  endpoints: builder => ({
    fetchDistrictRolesWithSuperAdmin: builder.query<
      AdminUserRolesResponse[],
      TAdminUserRolesParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/user_roles/?with_admin=true`
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        dispatch(fetchDistrictRolesWithSuperAdminBegin());
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDistrictRolesWithSuperAdminSuccess(district, data));
        } catch (error) {
          dispatch(fetchDistrictRolesWithSuperAdminFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }) => ({
                type: EUserTags.ADMIN_USER_ROLES,
                id
              })),
              {
                type: EUserTags.ADMIN_USER_ROLES,
                id: EUserTags.ADMIN_USER_ROLES
              }
            ]
          : [
              {
                type: EUserTags.ADMIN_USER_ROLES,
                id: EUserTags.ADMIN_USER_ROLES
              }
            ]
    }),
    fetchUserById: builder.query<TUserData, TUserByIdParams>({
      query: ({ district, id }) => ({
        method: 'GET',
        url: `${district}/auth/users/${id}/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchUserByIdBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchUserByIdSuccess(data));
          dispatch(addUserToList(data));
        } catch (error) {
          dispatch(fetchUserByIdFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              { type: EUserTags.USER_BY_ID, id: data.id },

              {
                type: EUserTags.USER_BY_ID,
                id: EUserTags.USER_BY_ID
              }
            ]
          : [
              {
                type: EUserTags.USER_BY_ID,
                id: EUserTags.USER_BY_ID
              }
            ]
    }),
    fetchAuthUsersData: builder.query<TUserData, TGlobalAuthUserParams>({
      query: ({ userId }) => ({
        method: 'GET',
        url: `auth/users/${userId}/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchUserByIdBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchUserByIdSuccess(data));
          dispatch(addUserToList(data));
        } catch (error) {
          dispatch(fetchUserByIdFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              {
                type: EUserTags.GLOBAL_USERS_DATA,
                id: data.id
              },
              {
                type: EUserTags.GLOBAL_USERS_DATA,
                id: EUserTags.GLOBAL_USERS_DATA
              }
            ]
          : [
              {
                type: EUserTags.GLOBAL_USERS_DATA,
                id: EUserTags.GLOBAL_USERS_DATA
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchDistrictRolesWithSuperAdminQuery,
  useLazyFetchUserByIdQuery,
  useLazyFetchAuthUsersDataQuery
} = usersApi;
