import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  editLayerFormSuccess,
  fetchLayerFormsBegin,
  fetchLayerFormsFailed,
  fetchLayerFormsSuccess,
  getUserFormsSuccess
} from '@/store/actions/editDataActions';
import { showSuccess } from '@/store/actions/globalActions';

import handleApiError from '@/utils/handleApiError';

import {
  EEditDataTags,
  TDeleteLayerFormParams,
  TEditDataParams,
  TLayerFormsParams,
  TUpdateLayerFormParams
} from '@/types/api-types/edit-data';
import {
  TAdminLayerForm,
  TEditDataResponse,
  TLayerFormsResponse
} from '@/types/responses/edit-data';

export const editDataApi = createApi({
  reducerPath: 'editDataApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EEditDataTags.USER_FORMS, EEditDataTags.LAYER_FORMS],
  endpoints: builder => ({
    fetchUserForms: builder.query<TEditDataResponse[], TEditDataParams>({
      query: ({ district, mapPortalCompositionId }) => ({
        method: 'GET',
        url: `${district}/forms/`,
        params: { map_portal_composition_id: mapPortalCompositionId }
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(getUserFormsSuccess(district, data));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EEditDataTags.USER_FORMS,
                id: id ?? ''
              })),
              {
                type: EEditDataTags.USER_FORMS,
                id: EEditDataTags.USER_FORMS
              }
            ]
          : [
              {
                type: EEditDataTags.USER_FORMS,
                id: EEditDataTags.USER_FORMS
              }
            ]
    }),
    fetchLayerForms: builder.query<TLayerFormsResponse, TLayerFormsParams>({
      query: ({ district, query = undefined, pageSize, page }) => ({
        method: 'GET',
        url: `${district}/admin_layer_forms/`,
        params: {
          name: query?.name,
          layer: query?.layer,
          group: query?.group,
          subscription_group: query?.subscription_group,
          page_size: pageSize,
          page
        }
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        dispatch(fetchLayerFormsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(
            fetchLayerFormsSuccess({
              district,
              forms: data
            })
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchLayerFormsFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.results.map(({ id }: { id: number }) => ({
                type: EEditDataTags.LAYER_FORMS,
                id
              })),
              {
                type: EEditDataTags.LAYER_FORMS,
                id: EEditDataTags.LAYER_FORMS
              }
            ]
          : [
              {
                type: EEditDataTags.LAYER_FORMS,
                id: EEditDataTags.LAYER_FORMS
              }
            ]
    }),
    updateLayerForm: builder.mutation<TAdminLayerForm, TUpdateLayerFormParams>({
      query: ({ district, id, data }) => ({
        method: 'PATCH',
        url: `${district}/admin_layer_forms/${id}/`,
        data
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedAdminLayerForm } = await queryFulfilled;

          dispatch(editLayerFormSuccess(district, updatedAdminLayerForm));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      invalidatesTags: updatedAdminLayerForm =>
        updatedAdminLayerForm
          ? [{ type: EEditDataTags.LAYER_FORMS, id: updatedAdminLayerForm.id }]
          : []
    }),
    deleteLayerForm: builder.mutation<TAdminLayerForm, TDeleteLayerFormParams>({
      query: ({ district, id }) => ({
        method: 'DELETE',
        url: `${district}/admin_layer_forms/${id}/`
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(showSuccess('Poprawnie usunięto edycję'));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      invalidatesTags: [{ type: EEditDataTags.LAYER_FORMS }]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchUserFormsQuery,
  useLazyFetchLayerFormsQuery,
  useFetchLayerFormsQuery,
  useUpdateLayerFormMutation,
  useDeleteLayerFormMutation
} = editDataApi;
