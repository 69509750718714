export enum EGlobalTags {
  HEADER_LINKS = 'headerLinks'
}

export type THeaderLinksParams = {
  district?: string;
  map_portal?: number;
  map_portal_composition?: number;
};

export type TGlobalSettingsParams = {
  admin: boolean;
};
