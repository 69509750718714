export const FETCH_DISTRICTS_BEGIN = 'FETCH_DISTRICTS_BEGIN';
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
export const FETCH_DISTRICTS_FAILED = 'FETCH_DISTRICTS_FAILED';

export const SORT_DISTRICTS_BEGIN = 'SORT_DISTRICTS_BEGIN';
export const SORT_DISTRICTS_SUCCESS = 'SORT_DISTRICTS_SUCCESS';
export const SORT_DISTRICTS_FAILED = 'SORT_DISTRICTS_FAILED';

export const FETCH_DISTRICT_DETAILS_BEGIN = 'FETCH_DISTRICT_DETAILS_BEGIN';
export const FETCH_DISTRICT_DETAILS_SUCCESS = 'FETCH_DISTRICT_DETAILS_SUCCESS';

export const UPDATE_DISTRICT_DETAILS_BEGIN = 'UPDATE_DISTRICT_DETAILS_BEGIN';
export const UPDATE_DISTRICT_DETAILS_SUCCESS =
  'UPDATE_DISTRICT_DETAILS_SUCCESS';
export const UPDATE_DISTRICT_DETAILS_FAILED = 'UPDATE_DISTRICT_DETAILS_FAILED';

export const FETCH_PRECINTS_BEGIN = 'FETCH_PRECINTS_BEGIN';
export const FETCH_PRECINTS_SUCCESS = 'FETCH_PRECINTS_SUCCESS';

export const FETCH_PARCELS_LIST_BEGIN = 'FETCH_PARCELS_LIST_BEGIN';
export const FETCH_PARCELS_LIST_SUCCESS = 'FETCH_PARCELS_LIST_SUCCESS';

export const FETCH_APP_DISTRICT_BEGIN = 'FETCH_APP_DISTRICT_BEGIN';
export const FETCH_APP_DISTRICT_SUCCESS = 'FETCH_APP_DISTRICT_SUCCESS';

export const FETCH_DISTRICT_ROLES_BEGIN = 'FETCH_DISTRICT_ROLES_BEGIN';
export const FETCH_DISTRICT_ROLES_SUCCESS = 'FETCH_DISTRICT_ROLES_SUCCESS';
export const FETCH_DISTRICT_ROLES_FAILED = 'FETCH_DISTRICT_ROLES_FAILED';

export const FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS =
  'FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS';
export const FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN =
  'FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN';
export const FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED =
  'FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED';

export const SET_DISTRICT_BOUNDS = 'SET_DISTRICT_BOUNDS';

export const CLEAR_DISTRICTS_DATA_REDUCER_STATE =
  'CLEAR_DISTRICTS_DATA_REDUCER_STATE';

export const FETCH_DISTRICT_APPEARANCES_BEGIN =
  'FETCH_DISTRICT_APPEARANCES_BEGIN';
export const FETCH_DISTRICT_APPEARANCES_SUCCESS =
  'FETCH_DISTRICT_APPEARANCES_SUCCESS';
export const FETCH_DISTRICT_APPEARANCES_FAILED =
  'FETCH_DISTRICT_APPEARANCES_FAILED';

export const ADD_DISTRICT_APPEARANCE_BEGIN = 'ADD_DISTRICT_APPEARANCE_BEGIN';
export const ADD_DISTRICT_APPEARANCE_SUCCESS =
  'ADD_DISTRICT_APPEARANCE_SUCCESS';
export const ADD_DISTRICT_APPEARANCE_FAILED = 'ADD_DISTRICT_APPEARANCE_FAILED';

export const EDIT_DISTRICT_APPEARANCE_BEGIN = 'EDIT_DISTRICT_APPEARANCE_BEGIN';
export const EDIT_DISTRICT_APPEARANCE_SUCCESS =
  'EDIT_DISTRICT_APPEARANCE_SUCCESS';
export const EDIT_DISTRICT_APPEARANCE_FAILED =
  'EDIT_DISTRICT_APPEARANCE_FAILED';

export const DELETE_DISTRICT_APPEARANCE_BEGIN =
  'DELETE_DISTRICT_APPEARANCE_BEGIN';
export const DELETE_DISTRICT_APPEARANCE_SUCCESS =
  'DELETE_DISTRICT_APPEARANCE_SUCCESS';
export const DELETE_DISTRICT_APPEARANCE_FAILED =
  'DELETE_DISTRICT_APPEARANCE_FAILED';

export const FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN =
  'FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN';
export const FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS =
  'FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS';
export const FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED =
  'FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED';

export const UPDATE_APP_DISTRICT_BEGIN = 'UPDATE_APP_DISTRICT_BEGIN';
export const UPDATE_APP_DISTRICT_SUCCESS = 'UPDATE_APP_DISTRICT_SUCCESS';
export const UPDATE_APP_DISTRICT_FAILED = 'UPDATE_APP_DISTRICT_FAILED';

export const SAVE_APPLIED_APPEARANCE = 'SAVE_APPLIED_APPEARANCE';
export const CLEAR_APPLIED_APPEARANCE = 'CLEAR_APPLIED_APPEARANCE';

export const FETCH_DISTRICTS_SETTINGS_SUCCESS =
  'FETCH_DISTRICTS_SETTINGS_SUCCESS';

export const FETCH_DISTRICT_SETTINGS_SUCCESS =
  'FETCH_DISTRICT_SETTINGS_SUCCESS';
export const FETCH_DISTRICT_SETTINGS_FAILED = 'FETCH_DISTRICT_SETTINGS_FAILED';

export const UPDATE_DISTRICT_SETTINGS_BEGIN = 'UPDATE_DISTRICT_SETTINGS_BEGIN';
export const UPDATE_DISTRICT_SETTINGS_SUCCESS =
  'UPDATE_DISTRICT_SETTINGS_SUCCESS';
export const UPDATE_DISTRICT_SETTINGS_FAILED =
  'UPDATE_DISTRICT_SETTINGS_FAILED';

export const GET_ALL_DISTRICT_TAGS_BEGIN = 'GET_ALL_DISTRICT_TAGS_BEGIN';
export const GET_ALL_DISTRICT_TAGS_SUCCESS = 'GET_ALL_DISTRICT_TAGS_SUCCESS';
export const GET_ALL_DISTRICT_TAGS_FAILED = 'GET_ALL_DISTRICT_TAGS_FAILED';

export const CLEAR_DISTRICT_APPEARANCES = 'CLEAR_DISTRICT_APPEARANCES';

export const UPDATE_DISTRICT_BORDER_BEGIN = 'UPDATE_DISTRICT_BORDER_BEGIN';
export const UPDATE_DISTRICT_BORDER_SUCCESS = 'UPDATE_DISTRICT_BORDER_SUCCESS';
export const UPDATE_DISTRICT_BORDER_FAILED = 'UPDATE_DISTRICT_BORDER_FAILED';

export const FETCH_PORTAL_3D_SETTINGS_BEGIN = 'FETCH_PORTAL_3D_SETTINGS_BEGIN';
export const FETCH_PORTAL_3D_SETTINGS_SUCCESS =
  'FETCH_PORTAL_3D_SETTINGS_SUCCESS';
export const FETCH_PORTAL_3D_SETTINGS_FAILED =
  'FETCH_PORTAL_3D_SETTINGS_FAILED';

export const FETCH_OTHER_TOPICAL_DATA_SETTINGS_BEGIN =
  'FETCH_OTHER_TOPICAL_DATA_SETTINGS_BEGIN';
export const FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS =
  'FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS';
export const FETCH_OTHER_TOPICAL_DATA_SETTINGS_FAILED =
  'FETCH_OTHER_TOPICAL_DATA_SETTINGS_FAILED';

export const GET_GLOBAL_DISTRICT_SETTINGS_STARTED =
  'GET_GLOBAL_DISTRICT_SETTINGS_STARTED';
export const GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS =
  'GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS';
export const GET_GLOBAL_DISTRICT_SETTINGS_FAILED =
  'GET_GLOBAL_DISTRICT_SETTINGS_FAILED';

export const GET_DISTRICT_QRCODES_CONFIGURATION_BEGIN =
  'GET_DISTRICT_QRCODES_CONFIGURATION_BEGIN';
export const GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS =
  'GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS';
export const GET_DISTRICT_QRCODES_CONFIGURATION_FAILED =
  'GET_DISTRICT_QRCODES_CONFIGURATION_FAILED';

export const SAVE_DISTRICT_QRCODES_CONFIGURATION_BEGIN =
  'SAVE_DISTRICT_QRCODES_CONFIGURATION_BEGIN';
export const SAVE_DISTRICT_QRCODES_CONFIGURATION_SUCCESS =
  'SAVE_DISTRICT_QRCODES_CONFIGURATION_SUCCESS';
export const SAVE_DISTRICT_QRCODES_CONFIGURATION_FAILED =
  'SAVE_DISTRICT_QRCODES_CONFIGURATION_FAILED';

export const DELETE_DISTRICT_QRCODE_CONFIGURATION_BEGIN =
  'DELETE_DISTRICT_QRCODE_CONFIGURATION_BEGIN';
export const DELETE_DISTRICT_QRCODE_CONFIGURATION_SUCCESS =
  'DELETE_DISTRICT_QRCODE_CONFIGURATION_SUCCESS';
export const DELETE_DISTRICT_QRCODE_CONFIGURATION_FAILED =
  'DELETE_DISTRICT_QRCODE_CONFIGURATION_FAILED';

export const CREATE_QRCODES_BEGIN = 'CREATE_QRCODES_BEGIN';
export const CREATE_QRCODES_SUCCESS = 'CREATE_QRCODES_SUCCESS';
export const CREATE_QRCODES_FAILED = 'CREATE_QRCODES_FAILED';

export const CHANGE_SEARCH_INPUT_VALUE = 'CHANGE_SEARCH_INPUT_VALUE';

export const FETCH_GLOBAL_THEMES_BEGIN = 'FETCH_GLOBAL_THEMES_BEGIN';
export const FETCH_GLOBAL_THEMES_SUCCESS = 'FETCH_GLOBAL_THEMES_SUCCESS';
export const FETCH_GLOBAL_THEMES_FAILED = 'FETCH_GLOBAL_THEMES_FAILED';

export const DELETE_GLOBAL_THEME_STARTED = 'DELETE_GLOBAL_THEME_STARTED';
export const DELETE_GLOBAL_THEME_SUCCESS = 'DELETE_GLOBAL_THEME_SUCCESS';
export const DELETE_GLOBAL_THEME_FAILED = 'DELETE_GLOBAL_THEME_FAILED';

export const ADD_GLOBAL_THEME_STARTED = 'ADD_GLOBAL_THEME_STARTED';

export const GET_SINGLE_GLOBAL_THEME_STARTED =
  'GET_SINGLE_GLOBAL_THEME_STARTED';
export const GET_SINGLE_GLOBAL_THEME_SUCCESS =
  'GET_SINGLE_GLOBAL_THEME_SUCCESS';
export const GET_SINGLE_GLOBAL_THEME_FAILED = 'GET_SINGLE_GLOBAL_THEME_FAILED';

export const EDIT_SINGLE_GLOBAL_THEME_STARTED =
  'EDIT_SINGLE_GLOBAL_THEME_STARTED';

export const SET_FETCHING_GLOBAL_THEMES = 'SET_FETCHING_GLOBAL_THEMES';
export const SET_GLOBAL_THEME_LOADING = 'SET_GLOBAL_THEME_LOADING';

export const GET_DISTRICT_EXTERNAL_SITES_BEGIN =
  'GET_DISTRICT_EXTERNAL_SITES_BEGIN';
export const GET_DISTRICT_EXTERNAL_SITES_SUCCESS =
  'GET_DISTRICT_EXTERNAL_SITES_SUCCESS';
export const GET_DISTRICT_EXTERNAL_SITES_FAILED =
  'GET_DISTRICT_EXTERNAL_SITES_FAILED';

export const ADD_DISTRICT_EXTERNAL_SITE_BEGIN =
  'ADD_DISTRICT_EXTERNAL_SITE_BEGIN';
export const ADD_DISTRICT_EXTERNAL_SITE_SUCCESS =
  'ADD_DISTRICT_EXTERNAL_SITE_SUCCESS';
export const ADD_DISTRICT_EXTERNAL_SITE_FAILED =
  'ADD_DISTRICT_EXTERNAL_SITE_FAILED';

export const UPDATE_DISTRICT_EXTERNAL_SITES_STARTED =
  'UPDATE_DISTRICT_EXTERNAL_SITES_STARTED';
export const UPDATE_DISTRICT_EXTERNAL_SITES_SUCCESS =
  'UPDATE_DISTRICT_EXTERNAL_SITES_SUCCESS';
export const UPDATE_DISTRICT_EXTERNAL_SITES_FAILED =
  'UPDATE_DISTRICT_EXTERNAL_SITES_FAILED';

export const DELETE_DISTRICT_EXTERNAL_SITE_BEGIN =
  'DELETE_DISTRICT_EXTERNAL_SITE_BEGIN';
export const DELETE_DISTRICT_EXTERNAL_SITE_SUCCESS =
  'DELETE_DISTRICT_EXTERNAL_SITE_SUCCESS';
export const DELETE_DISTRICT_EXTERNAL_SITE_FAILED =
  'DELETE_DISTRICT_EXTERNAL_SITE_FAILED';

export const UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_BEGIN =
  'UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_BEGIN';
export const UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_SUCCESS =
  'UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_SUCCESS';
export const UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_FAILED =
  'UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_FAILED';

export const SORT_ALL_DISTRICTS_BEGIN = 'SORT_ALL_DISTRICTS_BEGIN';
export const SORT_ALL_DISTRICTS_FINISHED = 'SORT_ALL_DISTRICTS_FINISHED';
