import {
  FETCH_SEARCHABLE_MODELS_BEGIN,
  FETCH_SEARCHABLE_MODELS_SUCCESS,
  FETCH_SEARCHABLE_MODELS_FAILED,
  FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN,
  FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS,
  FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED,
  FETCH_NAMES_MODELS_BEGIN,
  FETCH_NAMES_MODELS_SUCCESS,
  FETCH_NAMES_MODELS_FAILED,
  FETCH_DATA_MODEL_BEGIN,
  FETCH_DATA_MODEL_SUCCESS,
  FETCH_DATA_MODEL_FAILED,
  FETCH_OPTIONS_MODEL_BEGIN,
  FETCH_OPTIONS_MODEL_SUCCESS,
  FETCH_OPTIONS_MODEL_FAILED,
  UPDATE_DATA_MODEL_BEGIN,
  UPDATE_DATA_MODEL_SUCCESS,
  UPDATE_DATA_MODEL_FAILED,
  GET_MODELS_TRANSLATIONS_BEGIN,
  GET_MODELS_TRANSLATIONS_SUCCESS,
  GET_MODELS_TRANSLATIONS_FAILED,
  ADD_MODEL_TRANSLATION_BEGIN,
  ADD_MODEL_TRANSLATION_SUCCESS,
  ADD_MODEL_TRANSLATION_FAILED,
  EDIT_MODEL_TRANSLATION_BEGIN,
  EDIT_MODEL_TRANSLATION_SUCCESS,
  EDIT_MODEL_TRANSLATION_FAILED,
  DELETE_MODEL_TRANSLATION_BEGIN,
  DELETE_MODEL_TRANSLATION_SUCCESS,
  DELETE_MODEL_TRANSLATION_FAILED,
  FETCH_CONTENT_TYPE_LAYERS_BEGIN,
  FETCH_CONTENT_TYPE_LAYERS_SUCCESS,
  FETCH_CONTENT_TYPE_LAYERS_FAILED,
  FETCH_DISTRICT_MODELS_DATA_SUCCESS,
  GET_ALL_MODELS_TRANSLATIONS_SUCCESS
} from '../constants/modelsActionTypes';

import { showError, showSuccess } from './globalActions';

import axios from 'axios';
import queryString from 'query-string';

import { parseResponseError } from '../../utils/lib';

export const fetchSearchableModelsBegin = () => ({
  type: FETCH_SEARCHABLE_MODELS_BEGIN
});

export const fetchSearchableModelsSuccess = searchableModels => ({
  type: FETCH_SEARCHABLE_MODELS_SUCCESS,
  searchableModels
});

export const fetchSearchableModelsFailed = () => ({
  type: FETCH_SEARCHABLE_MODELS_FAILED
});

export const fetchDistrictSearchableModelsBegin = () => ({
  type: FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN
});

export const fetchDistrictSearchableModelsSuccess = (
  district,
  searchableModels
) => ({
  type: FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS,
  district,
  data: searchableModels
});

export const fetchDistrictSearchableModelsFailed = () => ({
  type: FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED
});

export const fetchNamesModelsBeign = () => ({
  type: FETCH_NAMES_MODELS_BEGIN
});

export const fetchNamesModelsSuccess = ({ models, compositionId }) => ({
  type: FETCH_NAMES_MODELS_SUCCESS,
  models,
  compositionId
});

export const fetchNamesModelsFailed = () => ({
  type: FETCH_NAMES_MODELS_FAILED
});

/**
 * Oczekuje na endpoint
 *
 * @param {string} district
 */
export const fetchNamesModels = (
  district,
  withoutEmpty = false,
  compositionId
) => dispatch => {
  dispatch(fetchNamesModelsBeign());
  const params = { without_empty: 1, map_portal_composition_id: compositionId };
  !withoutEmpty && delete params.without_empty;
  !compositionId && delete params.map_portal_composition_id;

  const url = `${district}/spatial_api/?`;
  axios
    .get(url, {
      params,
      paramsSerializer: params => queryString.stringify(params)
    })
    .then(response => {
      dispatch(
        fetchNamesModelsSuccess({ models: response.data, compositionId })
      );
    })
    .catch(err => {
      dispatch(fetchNamesModelsFailed());
    });
};

export const fetchDataModelBeign = () => ({
  type: FETCH_DATA_MODEL_BEGIN
});

export const fetchDataModelSuccess = (model, dataModel) => ({
  type: FETCH_DATA_MODEL_SUCCESS,
  model,
  dataModel
});

export const fetchDataModelFailed = dataModel => ({
  type: FETCH_DATA_MODEL_FAILED,
  dataModel
});

/**
 * Pobiera informacje o modelu
 *
 * @param {string} district
 */
export const fetchDataModel = ({
  url,
  model,
  data = {
    query: [],
    operators: '',
    ordering: '',
    descOrdering: true,
    currentCompositionId: ''
  },
  page,
  resultsPerPage,
  addLimitQueryParam = true,
  pagination_false = false,
  output_format
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchDataModelBeign());

    const params = {
      query: data.query,
      operators: data.operators,
      ordering: `${data.descOrdering ? '-' : ''}${data.ordering}`,
      page: page,
      page_size: resultsPerPage,
      map_portal_composition_id: data.currentCompositionId,
      pagination_false,
      output_format
    };

    if (!output_format) {
      delete params.output_format;
    }

    if (!pagination_false) {
      delete params.pagination_false;
    }

    if (!data.query) {
      delete params.query;
    }

    if (!data.operators) {
      delete params.operators;
    }

    if (!data.ordering) {
      delete params.ordering;
    }

    if (!resultsPerPage || !page) {
      delete params.page;
      delete params.page_size;
    }

    if (!data.currentCompositionId) {
      delete params.map_portal_composition_id;
    }

    axios
      .get(
        `${url}${url.includes('?') ? '' : '?'}${
          !url.includes('limit') && addLimitQueryParam && !params.page
            ? 'limit=5'
            : ''
        }`,
        {
          params,
          paramsSerializer: params => queryString.stringify(params)
        }
      )
      .then(({ data }) => {
        if (!data?.task_id) dispatch(fetchDataModelSuccess(model, data));

        resolve(data);
      })
      .catch(err => {
        dispatch(showError(parseResponseError(err)));
        dispatch(fetchDataModelFailed(model, []));
        reject(err);
      });
  });

export const fetchOptionsModelBegin = () => ({
  type: FETCH_OPTIONS_MODEL_BEGIN
});

export const fetchOptionsModelSuccess = options => ({
  type: FETCH_OPTIONS_MODEL_SUCCESS,
  options
});

export const fetchOptionsModelFailed = () => ({
  type: FETCH_OPTIONS_MODEL_FAILED
});

/**
 * Pobiera informacje
 *
 * @param {string} district
 */
export const fetchOptionsModel = (url, currentCompositionId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchOptionsModelBegin());

    const params = currentCompositionId
      ? { map_portal_composition_id: currentCompositionId }
      : {};

    axios
      .options(url, { params })
      .then(response => {
        dispatch(fetchOptionsModelSuccess(response.data));
        resolve(response);
      })
      .catch(err => {
        dispatch(fetchOptionsModelFailed());
        reject(err);
      });
  });

export const updateDataModelBegin = () => ({
  type: UPDATE_DATA_MODEL_BEGIN
});

export const updateDataModelSuccess = (updatedModel, id) => ({
  type: UPDATE_DATA_MODEL_SUCCESS,
  updatedModel,
  id
});

export const updateDataModelFailed = () => ({
  type: UPDATE_DATA_MODEL_FAILED
});

/**
 * Aktualizuje informacje o
 *
 * @param {string} district
 */
export const updateDataModel = (district, model, id, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateDataModelBegin());

    axios
      .patch(`${district}/spatial_api/${model}/${data.id}/`, data)
      .then(r => {
        dispatch(updateDataModelSuccess(r.data, id));
        dispatch(
          showSuccess('Pomyślnie zaktualizowano atrybuty i geometrię obiektu.')
        );
        resolve();
      })
      .catch(err => {
        dispatch(updateDataModelFailed());
        dispatch(showError('Wystąpił błąd podczas aktualizacji atrybutu.'));
        reject(err);
      });
  });

//RTQ
export const getModelsTranslationsBegin = () => ({
  type: GET_MODELS_TRANSLATIONS_BEGIN
});

export const getModelsTranslationsSuccess = (mapPortalId, translations) => ({
  type: GET_MODELS_TRANSLATIONS_SUCCESS,
  mapPortalId,
  translations
});

export const getAllModelsTranslationsSuccess = translations => ({
  type: GET_ALL_MODELS_TRANSLATIONS_SUCCESS,
  translations
});

export const getModelsTranslationsFailed = () => ({
  type: GET_MODELS_TRANSLATIONS_FAILED
});

export const addModelTranslationBegin = () => ({
  type: ADD_MODEL_TRANSLATION_BEGIN
});

export const addModelTranslationSuccess = (mapPortalId, translation) => ({
  type: ADD_MODEL_TRANSLATION_SUCCESS,
  mapPortalId,
  translation
});

export const addModelTranslationFailed = () => ({
  type: ADD_MODEL_TRANSLATION_FAILED
});

export const addModelTranslation = (mapPortalId, translation) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addModelTranslationBegin());

    axios
      .post('translation_model_field/', translation)
      .then(({ data }) => {
        dispatch(addModelTranslationSuccess(mapPortalId, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(addModelTranslationFailed());
        reject(err);
      });
  });

export const editModelTranslationBegin = () => ({
  type: EDIT_MODEL_TRANSLATION_BEGIN
});

export const editModelTranslationSuccess = (mapPortalId, translation) => ({
  type: EDIT_MODEL_TRANSLATION_SUCCESS,
  mapPortalId,
  translation
});

export const editModelTranslationFailed = () => ({
  type: EDIT_MODEL_TRANSLATION_FAILED
});

export const editModelTranslation = (
  mapPortalId,
  translationId,
  value
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editModelTranslationBegin());

    axios
      .patch(`translation_model_field/${translationId}/`, {
        translated_value: value
      })
      .then(({ data }) => {
        dispatch(editModelTranslationSuccess(mapPortalId, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(editModelTranslationFailed());
        reject(err);
      });
  });

export const deleteModelTranslationBegin = () => ({
  type: DELETE_MODEL_TRANSLATION_BEGIN
});

export const deleteModelTranslationSuccess = (mapPortalId, translationId) => ({
  type: DELETE_MODEL_TRANSLATION_SUCCESS,
  mapPortalId,
  translationId
});

export const deleteModelTranslationFailed = () => ({
  type: DELETE_MODEL_TRANSLATION_FAILED
});

export const deleteModelTranslation = (
  mapPortalId,
  translationId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteModelTranslationBegin());

    axios
      .delete(`translation_model_field/${translationId}/`)
      .then(({ data }) => {
        dispatch(deleteModelTranslationSuccess(mapPortalId, translationId));
        resolve(data);
      })
      .catch(err => {
        dispatch(deleteModelTranslationFailed());
        reject(err);
      });
  });

export const fetchContentTypeLayersBegin = () => ({
  type: FETCH_CONTENT_TYPE_LAYERS_BEGIN
});

export const fetchContentTypeLayersSuccess = payload => ({
  type: FETCH_CONTENT_TYPE_LAYERS_SUCCESS,
  payload
});

export const fetchContentTypeLayersFailed = () => ({
  type: FETCH_CONTENT_TYPE_LAYERS_FAILED
});

export const fetchContentTypeLayers = ({
  prefix,
  mapPortalId,
  mapPortalCompositionId
}) => dispatch => {
  dispatch(fetchContentTypeLayersBegin());

  axios
    .get(
      `${prefix}/map_portal/${mapPortalId}/map_portal_compositions/${mapPortalCompositionId}/content_types/`
    )
    .then(({ data }) => {
      dispatch(fetchContentTypeLayersSuccess(data));
    })
    .catch(err => {
      dispatch(fetchContentTypeLayersFailed());
    });
};

export const fetchDistrictModelsDataSuccess = payload => ({
  type: FETCH_DISTRICT_MODELS_DATA_SUCCESS,
  payload
});
