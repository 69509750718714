import { TDjangoModelData } from '../responses/django-models-data-response';
import { TPopupQueryParams } from '@/components/InformationModal/types';
import { TFeatureInfoData } from '../responses/feature-info-response';
import { TQueryParams } from '../query-params';
import {
  TDefaultLayerConfig,
  TDefaultLayerConfigAttribute
} from '../responses/default-layer-config';

export const enum EPopupTags {
  LAYER_CONFIG = 'layerConfig',
  FEATURE_INFO_DATA = 'featureInfoData',
  FEATURE_INFO_JOINS = 'featureInfoJoins',
  COVER_PATH = 'coverPath',
  ATTACHMENTS = 'attachments',
  DJANGO_MODELS_LAYERS = 'djangoModelLayers',
  DJANGO_MODELS_DATA = 'djangoModelData',
  CONTENT_TYPE_INFO = 'contentTypeInfo',
  FEATURE_INFO_LAYERS = 'featureInfoLayers',
  ALL_FEATURE_INFO_LAYERS = 'allFeatureInfoLayers',
  SHARE_PLOTS_IDENTIFICATION = 'sharePlotsIdentification'
}

export type TFetchAttachmentsParams = {
  isDjangoModel?: boolean;
  district: string;
  mapPortalId: number;
  layerId: number;
  objectId: string | number;
};

export type TFetchFeatureInfoLayersQueryParams = {
  lat?: number;
  lng?: number;
  parcel?: string;
} & Partial<TPopupQueryParams>;

export type TFetchFeatureInfoLayersParams = {
  district: string;
  compositionId: number;
  params: TFetchFeatureInfoLayersQueryParams;
};

export type TFetchFeatureInfoDataParams = {
  district: string;
  projectId: number;
  mapPortalCompositionId: number;
  data: TQueryParams;
};

export type TFetchFeatureInfoJoinsParams = {
  district: string;
  mapPortalCompositionId: number;
  layerConfigId: number;
};

export type TFetchCoverPath = Omit<TFetchAttachmentsParams, 'isWms'> & {
  district: string;
  mapPortalId: number;
  layerId: number;
  objectId: string | number;
  coverId: string | number;
  isDjangoModel?: boolean;
};

export type TTransformedFeatureInfoDataResponse = {
  count: number;
  results: TFeatureInfoData[];
};

export type TFetchDjangoModelsDataParams = {
  district: string;
  objectId: number;
  params: {
    composition_id: number;
    lat?: number;
    lng?: number;
    parcel?: string;
    page?: number;
    page_size?: number;
  };
};

export type TTransformedDjangoModelsDataResponse = {
  count: number;
  results: TDjangoModelData[];
};

export type TExtendedDefaultLogicAttribute = TDefaultLayerConfigAttribute & {
  name: string;
};

export type TParsedDefaultLayerConfig = Omit<TDefaultLayerConfig, 'attrs'> & {
  attributes: TExtendedDefaultLogicAttribute[];
};

export type TFetchContentTypeInfoParams = {
  data: { composition_id: number; content_type_ids: number[] };
};

export type TFeatureInfoLayersQuery = {
  district: string;
  compositionId: number;
};

export type TSharePlotsIdentification = {
  parcels: string[];
};

export type TGetSharedPlotsIdentification = Record<'id' | 'district', string>;
