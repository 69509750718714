import { createApi } from '@reduxjs/toolkit/query/react';

import {
  fetchDistrictRolesBegin,
  fetchDistrictRolesSuccess,
  fetchDistrictRolesFailed,
  fetchPublicMapPortalAppearanceSuccess,
  fetchPublicMapPortalAppearanceFailed,
  fetchPublicMapPortalAppearanceBegin,
  sortAllDistrictsBegin,
  fetchAppDistrict,
  sortAllDistrictsFinished,
  fetchDistrictsSettingsSuccess
} from '@/store/actions/districtActions';
import { showError, showSuccess } from '@/store/actions/globalActions';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';
import buildUrlParams from '@/utils/buildUrlParams';
import { endpointWithParams, parseResponseError } from '@/utils/lib';
import handleApiError from '@/utils/handleApiError';

import {
  API_APP_DISTRICT_ALPHABETIZE_SINGLE,
  API_APPEARANCE,
  API_APPEARANCE_LIST,
  API_APPEARANCE_SINGLE,
  API_DISTRICT_ROLES
} from '../consts';

import { TDjangoPaginationResponse } from '@/types/django-pagination-response';
import { TAppearance, TMapPortalAppearance } from '@/types/district';
import { TDistrictRolesResponse } from '@/types/responses/district';
import { TDistrictDetails } from '@/types/map-portal';
import {
  EDistrictTags,
  TDistrictRolesParams,
  TAppearanceParams,
  TMapPortalAppearanceQuery
} from '@/types/api-types/district';
import { TDistrictSettings } from '@/types/district-settings';

export const districtApi = createApi({
  reducerPath: 'districtApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EDistrictTags.DISTRICT_ROLES,
    EDistrictTags.MAP_PORTAL_APPEARANCE,
    EDistrictTags.APPEARANCE,
    EDistrictTags.DISTRICTS_SETTINGS
  ],
  endpoints: builder => ({
    fetchCurrentDistrictRoles: builder.query<
      TDistrictRolesResponse[],
      TDistrictRolesParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: endpointWithParams(API_DISTRICT_ROLES, { district })
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchDistrictRolesBegin());
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDistrictRolesSuccess(arg.district, data));
        } catch (error) {
          dispatch(fetchDistrictRolesFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EDistrictTags.DISTRICT_ROLES,
                id: id ?? ''
              })),
              {
                type: EDistrictTags.DISTRICT_ROLES,
                id: EDistrictTags.DISTRICT_ROLES
              }
            ]
          : [
              {
                type: EDistrictTags.DISTRICT_ROLES,
                id: EDistrictTags.DISTRICT_ROLES
              }
            ]
    }),
    fetchMapPortalAppearance: builder.query<
      TMapPortalAppearance,
      TMapPortalAppearanceQuery
    >({
      query: ({ prefix, appearance }) => ({
        method: 'GET',
        url: endpointWithParams(API_APPEARANCE_LIST, {
          district: prefix,
          appearance
        })
      }),
      onQueryStarted: async (
        { prefix, appearance },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchPublicMapPortalAppearanceBegin());
        try {
          const { data } = await queryFulfilled;

          dispatch(
            fetchPublicMapPortalAppearanceSuccess(prefix, appearance, data)
          );
        } catch (error) {
          dispatch(fetchPublicMapPortalAppearanceFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              {
                type: EDistrictTags.MAP_PORTAL_APPEARANCE,
                id: data.id ?? ''
              },
              {
                type: EDistrictTags.MAP_PORTAL_APPEARANCE,
                id: EDistrictTags.MAP_PORTAL_APPEARANCE
              }
            ]
          : [
              {
                type: EDistrictTags.MAP_PORTAL_APPEARANCE,
                id: EDistrictTags.MAP_PORTAL_APPEARANCE
              }
            ]
    }),
    sortAllDistricts: builder.query<TDistrictDetails[], null>({
      query: () => ({
        method: 'GET',
        url: API_APP_DISTRICT_ALPHABETIZE_SINGLE
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(sortAllDistrictsBegin());

        try {
          await queryFulfilled;

          dispatch(fetchAppDistrict());
          dispatch(showSuccess('Sortowanie jednostek przebiegło pomyślnie'));
        } catch (error) {
          dispatch(showError('Wystąpił błąd'));
        } finally {
          dispatch(sortAllDistrictsFinished());
        }
      }
    }),
    // APPEARANCE
    getAppearances: builder.query<
      TDjangoPaginationResponse<TAppearance[]>,
      TAppearanceParams
    >({
      query: ({ district, page, pageSize }) => ({
        method: 'GET',
        url: buildUrlParams(endpointWithParams(API_APPEARANCE, { district }), {
          page,
          pageSize
        })
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      },
      providesTags: data =>
        data?.results
          ? [
              ...data?.results.map(({ id }: TAppearance) => ({
                type: EDistrictTags.APPEARANCE,
                id
              })),
              {
                type: EDistrictTags.APPEARANCE,
                id: EDistrictTags.APPEARANCE
              }
            ]
          : [
              {
                type: EDistrictTags.APPEARANCE,
                id: EDistrictTags.APPEARANCE
              }
            ]
    }),
    deleteAppearance: builder.mutation<TAppearance, TAppearanceParams>({
      query: ({ district, id }) => ({
        method: 'DELETE',
        url: endpointWithParams(API_APPEARANCE_SINGLE, {
          district,
          id
        })
      }),
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: EDistrictTags.APPEARANCE, id },
          {
            type: EDistrictTags.APPEARANCE,
            id: EDistrictTags.APPEARANCE
          }
        ];
      }
    }),
    fetchDistrictsSettings: builder.query<TDistrictSettings[], null>({
      query: () => ({
        method: 'GET',
        url: `district_settings/`
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDistrictsSettingsSuccess(data));
        } catch (error) {
          dispatch(
            showError('Pobieranie aktualnych ustawień nie powiodło się!')
          );

          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data?.map(({ id }) => ({
                type: EDistrictTags.DISTRICTS_SETTINGS,
                id
              })),
              {
                type: EDistrictTags.DISTRICTS_SETTINGS,
                id: EDistrictTags.DISTRICTS_SETTINGS
              }
            ]
          : [
              {
                type: EDistrictTags.DISTRICTS_SETTINGS,
                id: EDistrictTags.DISTRICTS_SETTINGS
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchCurrentDistrictRolesQuery,
  useLazyFetchMapPortalAppearanceQuery,
  useLazySortAllDistrictsQuery,
  useLazyGetAppearancesQuery,
  useGetAppearancesQuery,
  useDeleteAppearanceMutation,
  useLazyFetchDistrictsSettingsQuery
} = districtApi;
